import React, { Component } from 'react';
import { Grid, Button, Typography, Slide, Dialog, Paper, Popper, Link } from '@material-ui/core';
import { Toolbar, AppBar, IconButton, Avatar, Backdrop, CircularProgress } from '@material-ui/core';
import { Card, CardActionArea, CardMedia, CardContent, Snackbar, Box } from '@material-ui/core';
import { KeyboardArrowLeft } from '@material-ui/icons';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import MuiAlert from '@material-ui/lab/Alert';

import '../css/Generic.css'
import {Utils} from '../generic/Utils';
import axios from 'axios';
import { CarrosAvatarDrawner } from './CarrosAvatarDrawner';
import { EventosViewDialog } from './EventosViewDialog';
import { CarrosEditDialog } from './CarrosEditDialog';
import { ConfirmDialog } from '../util/ConfirmDialog';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export class CarrosViewDialog extends Component {

    constructor(props) {
        super(props)

        this.state = { 
            car: {
                avatar: '../images/icons/cars/car.png',
                cor: '#F0EFEE',
                modelo: '',
                nome: '',
                ano: 2000,
                placa: '',
                observacoes: ''
            },
            selected: {},
            confirmDelete: false,
            tipoEvento: 'revisao',
            openAvatar: false,
            openEdit: false,
            openVacinasViewDialog: false,
            openConsultaViewDialog: false,
            openMedicacoesViewDialog: false
        }

        this.handleSave = this.handleSave.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
    }

    handleDelete() {
        //Atualizar 
        axios.delete(Utils.getUrl()+"carros/"+this.props.car._id).then(
            resp => {
                if ( resp.status === 201 || resp.status === 200) {
                    
                    this.props.onClose()
                    this.props.onRefresh()

                    this.setState({car:{}, openEdit: false, openAvatar: false})
                }
                else console.log("Erro ao Deletar")
            }
        ).catch( e => {
            console.log("Erro ao Deletar "+e) 
        })
    }

    handleClose() {
        this.props.onClose()
    }

    componentDidMount() {
        this.setState({car: this.props.car})
    }

    handleSave() {

        //Atualizar 
        axios.get(Utils.getUrl()+"carros/"+this.props.car._id).then(
            resp => {
                if ( resp.status === 201 || resp.status === 200) {
                    let p = resp.data
                    this.props.onRefresh()
                    this.setState({car:p, successMessage: true, openEdit: false, openAvatar: false})
                }
                else console.log("Erro ao buscar")
            }
        ).catch( e => {
            console.log("Erro ao buscar "+e) 
        })
    }

    montarMenu() {
        return (
        <Grid container style={{padding: '10px'}}> 
        
            <Grid item xs={4} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Card style={{maxWidth: '100px'}} square={false} elevation={1} variant="elevation">
                    <CardActionArea onClick={()=>this.setState({openEventosViewDialog: true, tipoEvento: 'revisao'})}>      
                        <CardMedia component="img" src="../images/icons/revisao.png" />                                     
                        <CardContent style={{display: 'flex', justifyContent: 'center', paddingTop: '10px', paddingBottom: '10px'}}>
                            <Typography variant='body2'>                                                        
                                Revisões
                            </Typography>                                                    
                        </CardContent> 
                    </CardActionArea>
                </Card> 
            </Grid>

            <Grid item xs={4} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <Card style={{maxWidth: '100px'}} square={false} elevation={1} variant="elevation">
                    <CardActionArea onClick={()=>this.setState({openEventosViewDialog: true, tipoEvento: 'manutencao'})}>      
                        <CardMedia component="img" src="../images/icons/manutencao.png" />                                     
                        <CardContent style={{display: 'flex', justifyContent: 'center', paddingTop: '10px', paddingBottom: '10px'}}>
                            <Typography variant='body2'>                                                      
                                Manutenções
                            </Typography>                                                    
                        </CardContent> 
                    </CardActionArea>
                </Card> 
            </Grid>

            <Grid item xs={4} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Card style={{maxWidth: '100px'}} square={false} elevation={1} variant="elevation">
                    <CardActionArea onClick={()=>this.setState({openEventosViewDialog: true, tipoEvento: 'outros'})}>      
                        <CardMedia component="img" src="../images/icons/evento.png" style={{backgroundColor: "#91EFBD"}}/>                                     
                        <CardContent style={{display: 'flex', justifyContent: 'center', paddingTop: '10px', paddingBottom: '10px'}}>
                            <Typography variant='body2'>                                                        
                                Eventos
                            </Typography>                                                    
                        </CardContent> 
                    </CardActionArea>
                </Card> 
            </Grid>

        </Grid> 
        )
    }

    render() {

        return (
            <Dialog fullScreen 
                open={this.props.open} 
                onClose={this.handleClose} 
                onRendered={this.onRendered}
                TransitionComponent={Transition}>

                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={this.handleClose}>
                            <KeyboardArrowLeft />
                        </IconButton>

                        <Typography component="h1" variant="h6" color="inherit" noWrap style={{flexGrow: 1}}>
                            {this.state.car.nome}
                        </Typography>

                        <IconButton color="inherit" id="dotsIcon"  onClick={ () => {this.setState({openMenu: !this.state.openMenu})}} >
                            <MoreVertIcon />
                            <Popper open={this.state.openMenu} anchorEl={document.getElementById('dotsIcon')} placement={'bottom-end'} style={{zIndex: 10000}}>
                                <Paper style={{padding: '10px', display: 'flex', flexDirection: 'column', 
                                                backgroundColor: 'white !important', minWidth: '200px'}}>
                                    <Box pt={1}/>
                                    
                                    <Link color="primary" onClick={() => {this.setState({openEdit: true})}}>
                                        <Typography variant="body2" noWrap>
                                            Editar
                                        </Typography>
                                    </Link>
                                    
                                    <Box pt={3}/>
                                    <Link color="primary" onClick={ () => this.setState({confirmDelete: true}) } >
                                        <Typography variant="body2" noWrap>
                                            Excluir
                                        </Typography>
                                    </Link>
                                </Paper>
                            </Popper>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                
                <Grid container spacing={3} >  

                    <Grid item xs={12} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>                            
                        <Avatar src={this.state.car.avatar} 
                            style={{width: '100px', height: '100px', 
                                margin: '10px', marginTop: '30px', backgroundColor: this.state.car.cor}}
                            onClick={() => this.setState({openAvatar: true})}/>
                        
                        <Button variant="outlined"
                            size="small"
                            startIcon={<EditIcon />}
                            onClick={() => this.setState({openEdit: true})}>
                                Editar
                        </Button>
                    </Grid>
                    
                    <Grid item xs={12} style={{backgroundColor: '#FAFAFA'}}  alignItems="center">
                        
                        {this.montarMenu()}

                        <Grid container spacing={3} style={{padding: '20px'}}> 
                            <Grid item xs={12}>
                                <Typography variant="body2" style={{paddingBottom: '20px'}}>
                                    <b>Informações Gerais</b>
                                </Typography>
                                
                                <Paper>
                                    <Grid container spacing={3} style={{padding: '20px'}}> 
                                        <Grid item xs={4} sm={2}>
                                            <b>Nome</b>
                                        </Grid>
                                        <Grid item xs={8} sm={10}>
                                            {this.state.car.nome}
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            <b>Modelo</b>
                                        </Grid>
                                        <Grid item xs={8} sm={10}>
                                            {this.state.car.modelo?this.state.car.modelo:"Não informado"}
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            <b>Placa</b>
                                        </Grid>
                                        <Grid item xs={8} sm={10}>
                                            {this.state.car.placa?this.state.car.placa:"Não informado"}
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            <b>Ano</b>
                                        </Grid>
                                        <Grid item xs={8} sm={10}>
                                            {this.state.car.ano?this.state.car.ano:"Não informado"}
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            
                        </Grid>
                    </Grid>
                </Grid>

                <CarrosAvatarDrawner
                    open={this.state.openAvatar} 
                    car={this.state.car}
                    onClose={()=>this.setState({openAvatar: false})}
                    onSave={() => {
                        this.handleSave()
                    }}/>

                <CarrosEditDialog
                    open={this.state.openEdit}
                    car={this.state.car}
                    onClose={()=>this.setState({openEdit: false})}
                    onSave={() => {
                        this.handleSave()
                    }}/>

                <EventosViewDialog
                    open={this.state.openEventosViewDialog} 
                    car={this.state.car}
                    tipo={this.state.tipoEvento}
                    onClose={()=>this.setState({openEventosViewDialog: false})} />

                <Snackbar open={this.state.successMessage} autoHideDuration={6000} 
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => this.setState({successMessage:false})}>
                    <MuiAlert severity="success" elevation={6} variant="filled" onClose={ () => this.setState({successMessage:false})}>
                        Atualização realizada com sucesso!
                    </MuiAlert>
                </Snackbar>

                <ConfirmDialog open={this.state.confirmDelete}
                    handleClose={ () => this.setState({confirmDelete: false}) }
                    handleOk={this.handleDelete}
                    message="Você deseja realmente excluir? Essa operação não pode ser desfeita!"
                />

                <Backdrop
                    style={{ color: '#fff', zIndex: 10000 }}
                    open={this.state.saving}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Dialog>
        )
    }

}