import React, { Component } from 'react';
import { Typography, Slide, Dialog, Grid, MobileStepper } from '@material-ui/core';
import { Toolbar, AppBar, IconButton, Button, Avatar, Checkbox } from '@material-ui/core';
import { InputAdornment, Snackbar, TextField, Chip, Backdrop, CircularProgress } from '@material-ui/core';
import { List, ListItem, ListItemText, ListItemSecondaryAction, CardActionArea} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {KeyboardArrowRight, KeyboardArrowLeft } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import AddBoxIcon from '@material-ui/icons/AddBox';
import MuiAlert from '@material-ui/lab/Alert';

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';

import {Utils} from '../generic/Utils';
import axios from 'axios';
import '../css/Generic.css'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export class ConsultaFormDialog extends Component {
    
    constructor(props) {
        super(props)

        this.state = {            
            cadastro: {
                sintomas: [],
                medicacoes: [],
                valor: 0,
                data: new Date(),
                observacoes: ''
            },
            loading: false,
            sintoma: '',
            medicacao: '',
            posologia: '',
            medico: '',
            especialidade: '',
            inicio: new Date(),
            fim: new Date(),
            adicionarMedicacao: false,
            observacoesMedicacao: '',
            activeStep: 0,
            disableEspecialidade:false,

            listaPessoas: [],
            listaMedicos: [],
            listaSintomas: [],
            listaMedicacoes: [],

            showErroMessage: false,
            errorMedico: false,
            errorEspecialidade: false,
            errorMedicacao: false
        }

        this.handleClose = this.handleClose.bind(this)
        this.handleNext = this.handleNext.bind(this)
        this.handleBack = this.handleBack.bind(this)
        this.handleStep0 = this.handleStep0.bind(this)
        this.handleSave = this.handleSave.bind(this)
    }

    async handleSave() {
        let cadastro = {...this.state.cadastro}
        cadastro.userId = sessionStorage.getItem('userId')

        this.setState({
            saving: true, 
            showErroMessage:false,            
            sucessMessage: false,
            fatalErroMessage:false
        })

        //Montar objeto cadastro para salvar
        //Setar ser
        cadastro.ser = this.props.paciente

        //Setar Medico
        let s = this.state.medico
        let achou = false
        for (var i=0; i<this.state.listaMedicos.length; i++) {
            if ( s === this.state.listaMedicos[i].nome ) {
                cadastro.medico = this.state.listaMedicos[i]
                achou = true
                break
            }
        }
        
        if (!achou) {
            //Cadastrar medico
            let m = {
                userId: cadastro.userId,
                nome: this.state.medico,
                especialidade: this.state.especialidade
            }

            await axios.post(Utils.getUrl()+"medicos/",{...m}).then(
                resp => {
                    if ( resp.status === 201 || resp.status === 200) {       
                        cadastro.medico = resp.data    
                    }
                    else {
                        console.log('Erro ao cadastrar medico')
                        this.setState({fatalErroMessage:true, saving: false})
                    }
                }
            ).catch( e => {      
                console.log('Erro ao cadastrar medico')
                console.log(e)  
                this.setState({fatalErroMessage:true, saving: false})
            })
        }

        axios.post(Utils.getUrl()+"consultas/",{...cadastro}).then(
            resp => {
                if ( resp.status === 201 || resp.status === 200) {   

                    this.setState( {
                        successMessage: true,
                        saving: false
                    }) 
                    
                    this.handleClose(true)
                }
                else {
                    console.log('Erro ao cadastrar consulta')
                    this.setState({fatalErroMessage:true, saving: false})
                }
            }
        ).catch( e => {    
            console.log('Erro ao cadastrar consulta')
            console.log(e)  
            this.setState({fatalErroMessage:true, saving: false})
        })
    }

    handleClose(saving) {
        this.setState( {            
            cadastro: {
                sintomas: [],
                medicacoes: [],
                valor: 0,
                data: new Date(),
                observacoes: ''
            },
            loading: false,
            sintoma: '',
            medicacao: '',
            posologia: '',
            medico: '',
            especialidade: '',
            inicio: new Date(),
            fim: new Date(),
            observacoesMedicacao: '',
            activeStep: 0,
            disableEspecialidade:false,

            showErroMessage: false,
            errorMedico: false,
            errorEspecialidade: false,
            errorMedicacao: false
        })

        if (saving) {
            this.props.onSave()
        }
        else this.props.onClose()
    }

    componentDidMount() { 
        let userId = sessionStorage.getItem('userId')     
        this.setState({loading: true})

        //Buscar lista de médicos
        axios.get(Utils.getUrl()+"medicos/user/"+userId).then(
            resp => {
                if ( resp.status === 201 || resp.status === 200) {
                    let listaMedico = resp.data
                    this.setState({listaMedicos:listaMedico})                   
                    this.setState({loading: false})
                }
                else console.log("Erro ao buscar medicos")
            }
        )   
    }

    handleNext() {
        let userId = sessionStorage.getItem('userId')    

        if ( this.state.activeStep === 0 ) {
            this.handleStep0(userId)                
            return
        }

        if ( this.state.activeStep === 1 ) {
            //Buscar lista de medicacoes
            axios.get(Utils.getUrl()+"medicacoes/user/"+userId).then(
                resp => {
                    if ( resp.status === 201 || resp.status === 200) {
                        let lista = resp.data

                        let listaFinal = []
                        for (var i=0; i<lista.length; i++) {
                            let nome = lista[i].nome
                            if ( !listaFinal.includes(nome) ) {
                                listaFinal.push(nome)
                            }
                        }                    
                        this.setState({listaMedicacoes:listaFinal.sort()})

                        axios.get(Utils.getUrl()+"medicacoesRealizadas/user/"+userId).then(
                            resp => {
                                if ( resp.status === 201 || resp.status === 200) {
                                    let lista = resp.data

                                    for (var i=0; i<lista.length; i++) {
                                        let nome = lista[i].nome
                                        if ( !listaFinal.includes(nome) ) {
                                            listaFinal.push(nome)
                                        }
                                    }
                                    this.setState({listaMedicacoes:listaFinal.sort()})
                                }
                            }
                        )                    
                    }
                    else console.log("Erro ao buscar medicacoes")
                }
            ) 

           this.setState({activeStep: 2})
           return
        }
    }

    handleBack() {
        let step = this.state.activeStep
        this.setState({activeStep: step-1})
    }

    handleStep0(userId) {
        let nextStep = 1
        if ( this.state.medico.trim() === '' ) {
            this.setState({errorMedico: true, showErroMessage:true})
            nextStep = 0
        }
        if ( this.state.especialidade.trim() === '' ) {
            this.setState({errorEspecialidade: true, showErroMessage:true})
            nextStep = 0
        }

        if ( nextStep === 1 ) {
            
            //Buscar lista de sintomas
            axios.get(Utils.getUrl()+"sintomas/user/"+userId).then(
                resp => {
                    if ( resp.status === 201 || resp.status === 200) {
                        let lista = resp.data
                        console.log(lista)
                        this.setState({listaSintomas:lista})
                    }
                    else console.log("Erro ao buscar sintomas")
                }
            ) 
            
        }
        this.setState({activeStep: nextStep})
    }

    getStepContent() {
        switch (this.state.activeStep) {

            case 0:
                return (
                <Grid container spacing={3}>                          
                    <Grid item xs={12}>                        
                        <Autocomplete
                            id="medico"
                            freeSolo
                            disableClearable
                            getOptionLabel={(option) => option.toString()}
                            options={this.state.listaMedicos.map( (e) => e.nome)}
                            value={this.state.medico}          
                            onSelect={ () => {
                                let s = document.getElementById('medico').value
                                if ( s.trim() === '' ) return;

                                for (var i=0; i<this.state.listaMedicos.length; i++) {
                                    if ( s === this.state.listaMedicos[i].nome ) {
                                        this.setState({
                                            medico: this.state.listaMedicos[i].nome,
                                            especialidade: this.state.listaMedicos[i].especialidade,
                                            errorMedico: false,
                                            errorEspecialidade: false,
                                            disableEspecialidade: true
                                        })
                                        return;
                                    }
                                }
                                this.setState({disableEspecialidade:false})
                            }}                  
                            renderInput={(params) => (
                                <TextField {...params} 
                                    error={this.state.errorMedico}
                                    required
                                    label="Médico/Local"  
                                    variant="outlined"   
                                    value={this.state.medico}               
                                    onChange={ (e) => {
                                        this.setState( { medico: e.target.value, errorMedico: false} ) 
                                    }} 
                            />
                        )}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="especialidade" 
                            required
                            fullWidth
                            disabled={this.state.disableEspecialidade}
                            error={this.state.errorEspecialidade}
                            label="Especialidade"  
                            variant='outlined' 
                            value={this.state.especialidade} 
                            onChange={ (e) => {
                                this.setState( { especialidade: e.target.value, errorEspecialidade: false } ) 
                            }} />
                    </Grid>
                    <Grid item xs={12} md={6}>              
                        <MuiPickersUtilsProvider utils={LuxonUtils}>
                            <KeyboardDatePicker
                                id="data"
                                fullWidth
                                margin="normal"
                                format="dd/MM/yyyy"
                                label="Data" 
                                inputVariant='outlined'
                                InputLabelProps={{
                                    shrink: true,
                                }}     
                                value={this.state.cadastro.data}
                                onChange={ (e) => 
                                    this.setState( { cadastro: {...this.state.cadastro, 
                                    data: new Date(e)} } ) }
                            />   
                        </MuiPickersUtilsProvider> 
                    </Grid>

                    <Grid item xs={12} md={6}> 
                        <TextField id="valor" 
                            label="Valor"
                            margin="normal"
                            type="number"
                            variant='outlined'
                            InputLabelProps={{
                                shrink: true,
                                startadornment: <InputAdornment position="start">R$</InputAdornment>
                            }} 
                            fullWidth
                            value={this.state.cadastro.valor}
                            onChange={ (e) => 
                                this.setState( { cadastro: {...this.state.cadastro, 
                                valor: e.target.value} } ) }/>
                    </Grid>
                </Grid>
                );
            case 1:
                return (
                    <Grid container spacing={3}>                          
                        <Grid item xs={12}>  
                            <Typography variant="body1" color="inherit" noWrap style={{flexGrow: 1}}>
                                Quais os sintomas apresentados?
                            </Typography>
                        </Grid>

                    <Grid item xs={10} >                        
                        <Autocomplete
                            id="sintoma"
                            freeSolo
                            disableClearable
                            options={this.state.listaSintomas.map((option) => option.descricao)}
                            value={this.state.sintoma}
                            renderInput={(params) => (
                                <TextField {...params} 
                                    label=""  
                                    variant="outlined"                
                                    onChange={ (e) => {
                                        this.setState( {sintoma: e.target.value} ) 
                                    }} 
                                />
                        )}/>
                    </Grid>
                    <Grid item xs={2} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <IconButton 
                            size='medium'
                            color="primary"
                            onClick={ () => {
                                let s = document.getElementById('sintoma').value
                                if ( s.trim() === '' ) return;

                                let sint = {
                                    descricao: s
                                }

                                let sintomasAtuais = this.state.cadastro.sintomas
                                sintomasAtuais.push(sint)
                            
                                this.setState( { cadastro: {...this.state.cadastro, 
                                    sintomas: sintomasAtuais} } )

                                this.setState({sintoma: ""})
                            }}>
                                <AddBoxIcon />
                        </IconButton>
              
                    </Grid>

                    <Grid item xs={12}>                        
                    {
                        this.state.cadastro.sintomas.map( (e) => 
                            <Chip label={e.descricao}
                                color="secondary"
                                style={{marginRight: '2px'}}
                                onDelete={ () => {
                                    let sintomasAtuais = this.state.cadastro.sintomas.filter((s) => s.descricao !== e.descricao)
                                    this.setState( { cadastro: {...this.state.cadastro, 
                                        sintomas: sintomasAtuais} } )

                            }}/>
                        )
                    }
                    </Grid>

                    </Grid>
                )
            
            case 2:
                return (

                    <Grid container spacing={3} alignItems="center">
                        
                        <Grid item xs={12}>                        
                            <Autocomplete
                                id="medicacao"
                                freeSolo
                                disableClearable
                                options={this.state.listaMedicacoes.map((option) => option)}
                                value={this.state.medicacao}
                                renderInput={(params) => (
                                    <TextField {...params} 
                                        value={this.state.medicacao}
                                        label="Medicação"  
                                        variant="outlined"
                                        error={this.state.errorMedicacao}  
                                        required                             
                                        onChange={ (e) => {
                                            this.setState({errorMedicacao:false, medicacao: e.target.value} ) 
                                        }} 
                                    />
                            )}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField id="posologia" 
                                fullWidth
                                label="Posologia"  
                                variant='outlined' 
                                value={this.state.posologia}
                                onChange={ (e) => 
                                    this.setState( { posologia: e.target.value} ) }/>
                        </Grid>
                        <Grid item xs={6}>                        
                            <MuiPickersUtilsProvider utils={LuxonUtils}>
                                <KeyboardDatePicker
                                    id="dataInicio"
                                    fullWidth
                                    format="dd/MM/yyyy"
                                    label="Data de Início" 
                                    inputVariant='outlined'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}     
                                    value={this.state.inicio}
                                    onChange={ (e) => 
                                        this.setState( { inicio: new Date(e)} ) }
                                />   
                            </MuiPickersUtilsProvider>                        
                        </Grid>
                        <Grid item xs={6}>                        
                            <MuiPickersUtilsProvider utils={LuxonUtils}>
                                <KeyboardDatePicker
                                    id="data"
                                    fullWidth
                                    format="dd/MM/yyyy"
                                    label="Data Fim" 
                                    inputVariant='outlined'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}     
                                    value={this.state.fim}
                                    onChange={ (e) => 
                                        this.setState( { fim: new Date(e)} ) }
                                />   
                            </MuiPickersUtilsProvider>                        
                        </Grid>
                        <Grid item xs={12}>
                            <Checkbox
                                inputVariant='outlined'
                                checked={this.state.adicionarMedicacao}
                                onChange={ (e) => this.setState({adicionarMedicacao: e.target.checked})}
                            />Adicionar às medicações realizadas
                        </Grid>

                        <Grid item xs={12}  style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                            <Button
                                variant="outlined"
                                color="primary"
                                startIcon={         
                                    <AddCircleOutlineIcon />}
                                onClick={ () => {
                                    
                                    let nome = document.getElementById("medicacao").value
                                    if ( nome.trim() === '' ) {
                                        this.setState({errorMedicacao:true, showErroMessage: true})
                                        return
                                    }

                                    let med ={
                                        nome: nome,
                                        posologia: this.state.posologia,
                                        inicio: this.state.inicio,
                                        fim: this.state.fim,
                                        adicionarMedicacaoRealizada: this.state.adicionarMedicacao,
                                        observacoes: this.state.observacoesMedicacao
                                    }
                                    let listaAtual = this.state.cadastro.medicacoes
                                    listaAtual.push(med)

                                    this.setState({ cadastro: {...this.state.cadastro, 
                                        medicacoes: listaAtual} })

                                    
                                    this.setState({
                                        medicacao: '',
                                        posologia: '',
                                        inicio: new Date(),
                                        fim: new Date(),
                                        observacoesMedicacao: ''
                                    })    
                                    document.getElementById("medicacao").value = ''                              
                                }}>
                                Adicionar
                            </Button>              
                        </Grid>

                        <Grid item xs={12}>   
                            <List className="fullSizeList">                   
                            {
                                this.state.cadastro.medicacoes.map( (e) => 
                                    <CardActionArea>
                                        <ListItem key={e.nome}>
                                            <ListItemText primary={e.nome}
                                                secondary={e.posologia} />

                                            <ListItemSecondaryAction>
                                                <IconButton onClick={ () => {
                                                    let medicacoesAtuais = this.state.cadastro.medicacoes.filter((s) => s.nome !== e.nome)
                                                    this.setState( { cadastro: {...this.state.cadastro, 
                                                        medicacoes: medicacoesAtuais} } )
                                                } }>
                                                    <DeleteIcon/>   
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </CardActionArea>
                                )
                            }
                            </List>  
                        </Grid>
                        
                        <AppBar position="fixed" style={{top: 'auto', bottom: 0, backgroundColor: 'white'}}>
                            <Toolbar style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                <Button color="primary" variant='contained' onClick={this.handleSave}>
                                    Salvar
                                </Button>
                            </Toolbar>
                        </AppBar>
                    </Grid>
                )
            
            default: return;

        }
    }

    render() {
        return (
            <Dialog fullScreen 
                open={this.props.open} 
                onClose={this.handleClose} 
                TransitionComponent={Transition}>

                <AppBar position="static">
                    <Toolbar>

                        <Typography component="h1" variant="h6" color="inherit" noWrap style={{flexGrow: 1}}>
                            Nova Consulta
                        </Typography>

                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => this.handleClose(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <Grid container spacing={3} style={{backgroundColor: '#FAFAFA'}} > 
                    
                    <Grid item xs={2} style={{backgroundColor: '#FFF', marginTop: '10px'}}>  
                        <Avatar src={this.props.paciente.avatar} 
                                style={{width: '30px', height: '30px', margin: '10px'}}/>  
                    </Grid>
                    <Grid item xs={10} style={{display: 'flex', flexDirection: 'column', 
                        justifyContent: 'center', backgroundColor: '#FFF', marginTop: '10px'}}>      
                        <Typography variant='subtitle2' noWrap>                                                    
                            {this.props.paciente.nome}
                        </Typography> 

                    </Grid>

                    <Grid item xs={12} style={{backgroundColor: '#FAFAFA', height: '100%'}}  alignItems="center">

                        <Grid item xs={12}>
                            <MobileStepper
                                variant="dots"
                                steps={3}
                                position="static"
                                activeStep={this.state.activeStep}
                                nextButton={
                                    <Button size="small" onClick={this.handleNext} disabled={[2].includes(this.state.activeStep)}>
                                        Próximo <KeyboardArrowRight />
                                    </Button>
                                }
                                backButton={
                                    <Button size="small" onClick={this.handleBack} disabled={this.state.activeStep===0}>
                                        <KeyboardArrowLeft /> Voltar 
                                    </Button>
                                }
                            />
                        </Grid>

                        <Grid item xs={12} style={{margin: '15px'}}>
                            {this.getStepContent()}
                        </Grid>

                    </Grid>
                </Grid>

                <Snackbar open={this.state.showErroMessage} autoHideDuration={6000} 
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => this.setState({showErroMessage:false})}>
                    <MuiAlert severity="error" elevation={6} variant="filled" onClose={ () => this.setState({showErroMessage:false})}>
                        Por favor, verifique os campos e tente novamente.
                    </MuiAlert>
                </Snackbar>

                <Snackbar open={this.state.fatalErroMessage} autoHideDuration={6000} 
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => this.setState({fatalErroMessage:false})}>
                    <MuiAlert severity="error" elevation={6} variant="filled" onClose={ () => this.setState({fatalErroMessage:false})}>
                        Erro ao realizar cadastro. Por favor, tente novamente mais tarde.
                    </MuiAlert>
                </Snackbar>
                
                <Backdrop
                    style={{ color: '#fff', zIndex: 10000 }}
                    open={this.state.saving || this.state.loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Dialog>
        )
    }
}