import React, { Component } from 'react';
import { Typography, Grid, Avatar, Button, Badge } from '@material-ui/core';
import { Backdrop, CircularProgress, IconButton, Drawer } from '@material-ui/core/';
import { Card, CardHeader, CardMedia, CardContent } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import '../css/Generic.css'
import {Utils} from '../generic/Utils';
import axios from 'axios';
import {SeresDialog} from '../seres/SeresDialog'
import { CarrosFormDialog } from '../carros/CarrosFormDialog';
import DefaultSwiper from './DefaultSwiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import { SelecaoSerDrawer } from './SelecaoSerDrawer';
import { SelecaoCarroDrawer } from './SelecaoCarroDrawer';

export class Dashboard extends Component {

  constructor(props) {
    super(props)

    this.state = {
      pessoaAvatar: '',
      pessoaNome: '',
      tipo: 'pessoa',
      loading: true,
      openPetsDialog: false,
      openPessoaDialog: false,
      openPessoaSuccess: false,
      listaPessoas: [],
      listaPets: [],
      listaCarros: []
    }  

    this.buscarPessoas = this.buscarPessoas.bind(this)
    this.buscarPets = this.buscarPets.bind(this)
    this.buscarVeiculos = this.buscarVeiculos.bind(this)
  }

  buscarPessoas() {
    let userId = sessionStorage.getItem('userId')   
    this.setState({loading: true})

    //Pessoas
    axios.get(Utils.getUrl()+"seres/user/"+userId).then(
      resp1 => {
          if ( resp1.status === 201 || resp1.status === 200) {
              let pessoas = resp1.data
              this.setState({
                listaPessoas: pessoas,
                loading: false
              })             
          }
          else console.log("Erro ao buscar Pessoas")
      }
    ).catch( e => {
        console.log("Erro ao buscar Pessoas"+e) 
    })
  }

  buscarPets () {
    let userId = sessionStorage.getItem('userId')   
    this.setState({loading: true})

    //Pets
    axios.get(Utils.getUrl()+"seres/pets/user/"+userId).then(
      resp => {
          if ( resp.status === 201 || resp.status === 200) {
              let pets = resp.data
              this.setState({
                listaPets: pets,
                loading: false
              })              
          }
          else console.log("Erro ao buscar Pessoas")
      }
    ).catch( e => {
        console.log("Erro ao buscar Pessoas"+e) 
    })
  }

  buscarVeiculos() {
    let userId = sessionStorage.getItem('userId')   
    this.setState({loading: true})

    //Veiculos
    axios.get(Utils.getUrl()+"carros/user/"+userId).then(
      resp => {
          if ( resp.status === 201 || resp.status === 200) {
              let cars = resp.data
              this.setState({
                listaCarros: cars,
                loading: false
              })              
          }
          else console.log("Erro ao buscar Veiculos")
      }
    ).catch( e => {
        console.log("Erro ao buscar Veiculos"+e) 
    })
  }

  componentDidMount() {
    this.buscarPessoas();
    this.buscarPets();
    this.buscarVeiculos();
  }

  getEmpty() {
    let number = Math.floor(Math.random() * 4)+1;
    return (
      <Card style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>  
          <CardMedia component="img" src={"../images/empty/empty"+number+".png"}
            style={{maxWidth: '200px'}}/>                                     
          <CardContent style={{textAlign: 'center', padding: '10px'}}>
              <Typography variant='body2'>                                                        
                  Nada por aqui ainda...
              </Typography>                                                    
          </CardContent> 
      </Card> 
    )
  }

  render() {

    return (   
        <Grid container spacing={3} >

            <Grid item xs={12}>
              
              <Swiper
                slidesPerView={4}
                spaceBetween={5}
                freeMode={true}>

                <SwiperSlide>
                  <div style={{textAlign: 'center'}}>
                    <Badge anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} 
                        badgeContent={"+"} color="primary">
                      <Avatar src="../images/icons/medic.png" style={{width: '50px', height: '50px', cursor: 'pointer'}} 
                        onClick={ () => {this.setState({openSelectSer:true, tipoMenu: 'consulta'})}}/>
                    </Badge>
                    <Typography variant="subtitle2" color="textSecondary" style={{paddingTop: '5px'}}>                 
                      Consulta
                    </Typography>
                  </div>
                </SwiperSlide>
              
                <SwiperSlide>
                  <div style={{textAlign: 'center'}}>
                    <Badge anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} 
                        badgeContent={"+"} color="primary">
                      <Avatar src="../images/icons/medication.png" style={{width: '50px', height: '50px', cursor: 'pointer'}} 
                        onClick={ () => {this.setState({openSelectSer:true, tipoMenu: 'medicacao'})}}/>
                    </Badge>
                    <Typography variant="subtitle2" color="textSecondary" style={{paddingTop: '5px'}}>                 
                      Medicação
                    </Typography>
                  </div>
                </SwiperSlide>  

                <SwiperSlide>
                  <div style={{textAlign: 'center'}}>
                    <Badge anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} 
                        badgeContent={"+"} color="primary">
                      <Avatar src="../images/icons/vacine.png" style={{width: '50px', height: '50px', cursor: 'pointer'}} 
                        onClick={ () => {this.setState({openSelectSer:true, tipoMenu: 'vacina'})}}/>
                    </Badge>
                    <Typography variant="subtitle2" color="textSecondary" style={{paddingTop: '5px'}}>                 
                      Vacina
                    </Typography>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div style={{textAlign: 'center'}}>
                    <Badge anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} 
                        badgeContent={"+"} color="primary">
                      <Avatar src="../images/icons/revisao.png" style={{width: '50px', height: '50px', cursor: 'pointer'}}
                        onClick={ () => {this.setState({openSelectCar:true, tipoMenu: 'revisao'})}}/>
                    </Badge>
                    <Typography variant="subtitle2" color="textSecondary" style={{paddingTop: '5px'}}>                 
                      Revisão
                    </Typography>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div style={{textAlign: 'center'}}>
                    <Badge anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} 
                        badgeContent={"+"} color="primary">
                      <Avatar src="../images/icons/manutencao.png" style={{width: '50px', height: '50px', cursor: 'pointer'}}
                        onClick={ () => {this.setState({openSelectCar:true, tipoMenu: 'manutencao'})}}/>
                    </Badge>
                    <Typography variant="subtitle2" color="textSecondary" style={{paddingTop: '5px'}}>                 
                      Manutenção
                    </Typography>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div style={{textAlign: 'center'}}>
                    <Badge anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} 
                        badgeContent={"+"} color="primary">
                      <Avatar src="../images/icons/evento.png" style={{width: '50px', height: '50px', cursor: 'pointer'}}
                        onClick={ () => {this.setState({openSelectCar:true, tipoMenu: 'outros'})}}/>
                    </Badge>
                    <Typography variant="subtitle2" color="textSecondary" style={{paddingTop: '5px'}}>                 
                      Evento
                    </Typography>
                  </div>
                </SwiperSlide>

              </Swiper>
            </Grid>

            <Grid item xs={12}>
              <Card variant="outlined">
                <CardHeader title={"Pessoas ("+this.state.listaPessoas.length+")"} 
                  action={
                    <IconButton onClick={ () => this.setState({tipo: 'pessoa', openPessoaDialog: true})}>
                      <AddIcon color='primary'/>
                    </IconButton>
                }/>
                {
                  this.state.listaPessoas.length>0?
                  <DefaultSwiper lista={this.state.listaPessoas} onRefresh={this.buscarPessoas} isPet={false} tipo="pessoa"/>
                  :
                  this.getEmpty()
                }
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card variant="outlined">
                <CardHeader title={"Pets ("+this.state.listaPets.length+")"} 
                  action={
                    <IconButton onClick={ () => this.setState({tipo: 'animal', openPessoaDialog: true})}>
                      <AddIcon color='primary'/>
                    </IconButton>
                }/>
                {
                  this.state.listaPets.length>0?
                  <DefaultSwiper lista={this.state.listaPets} onRefresh={this.buscarPets} isPet={true} tipo="pessoa"/>
                  :
                  this.getEmpty()
                }
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card variant="outlined">
                <CardHeader title={"Veículos ("+this.state.listaCarros.length+")"} 
                  action={
                    <IconButton onClick={ () => this.setState({openCarDialog: true})}>
                      <AddIcon color='primary'/>
                    </IconButton>
                }/>
                {
                  this.state.listaCarros.length>0?
                  <DefaultSwiper lista={this.state.listaCarros} tipo="car" onRefresh={this.buscarVeiculos}/>
                  :
                  this.getEmpty()
                }
                
              </Card>
            </Grid>
  
            <SeresDialog 
              isPet={this.state.tipo==='pessoa'?false:true}
              open={this.state.openPessoaDialog} 
              onClose={()=>this.setState({openPessoaDialog: false})}
              onSave={ () => {
                let pessoaNome = sessionStorage.getItem("pessoaNome")
                let pessoaAvatar = sessionStorage.getItem("pessoaAvatar")
                this.state.tipo==='pessoa'?this.buscarPessoas():this.buscarPets()
                this.setState({openPessoaDialog: false, openPessoaSuccess: true, pessoaNome: pessoaNome, pessoaAvatar: pessoaAvatar})
              }}/>

              
            <CarrosFormDialog
              open={this.state.openCarDialog} 
              onClose={()=>this.setState({openCarDialog: false})}
              onSave={ () => {
                let carroNome = sessionStorage.getItem("carroNome")
                let carroAvatar = sessionStorage.getItem("carroAvatar")
                let carroCor = sessionStorage.getItem("carroCor")
                this.buscarVeiculos()
                this.setState({openCarDialog: false, openCarSuccess: true, carroNome: carroNome, carroAvatar: carroAvatar, carroCor: carroCor })
              }}/>

            <Drawer anchor="bottom" open={this.state.openPessoaSuccess} onClose={() => this.setState({openPessoaSuccess: false})}>
              
              <Grid container spacing={3}>  
                <Grid item xs={12} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>                            
                    <Avatar src={this.state.pessoaAvatar} 
                        style={{width: '100px', height: '100px', marginTop: '20px'}}/>
                </Grid>
                <Grid item xs={12} 
                  style={{display: 'flex', flexDirection: 'column', alignItems: 'center', 
                  marginBottom: '30px', marginRight: '20px', marginLeft: '20px', textAlign: 'center'}}>
                  <Typography variant='h6' style={{marginBottom: '20px'}}>
                    {this.state.pessoaNome} foi cadastro(a) com sucesso! 
                  </Typography>
                  <Button variant="contained" color="primary" onClick={() =>  this.setState({openPessoaSuccess:false}) }>
                      Entendi!
                  </Button>
                </Grid>
              </Grid>

            </Drawer>

            <Drawer anchor="bottom" open={this.state.openCarSuccess} onClose={() => this.setState({openCarSuccess: false})}>
              
              <Grid container spacing={3}>  
                <Grid item xs={12} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>                            
                    <Avatar src={this.state.carroAvatar} 
                        style={{width: '100px', height: '100px', marginTop: '20px', backgroundColor: this.state.carroCor}}/>
                </Grid>
                <Grid item xs={12} 
                  style={{display: 'flex', flexDirection: 'column', alignItems: 'center', 
                  marginBottom: '30px', marginRight: '20px', marginLeft: '20px', textAlign: 'center'}}>
                  <Typography variant='h6' style={{marginBottom: '20px'}}>
                    {this.state.carroNome} foi cadastro(a) com sucesso! 
                  </Typography>
                  <Button variant="contained" color="primary" onClick={() =>  this.setState({openCarSuccess:false}) }>
                      Entendi!
                  </Button>
                </Grid>
              </Grid>

            </Drawer>

            <SelecaoSerDrawer
              lista={this.state.listaPessoas.concat(this.state.listaPets)} 
              open={this.state.openSelectSer}
              tipo={this.state.tipoMenu}
              onClose={ () => this.setState({openSelectSer:false})} />

            <SelecaoCarroDrawer
              lista={this.state.listaCarros}
              open={this.state.openSelectCar}
              tipo={this.state.tipoMenu}
              onClose={ () => this.setState({openSelectCar:false})} />

            <Backdrop
                style={{ color: '#fff', zIndex: 10000 }}
                open={this.state.loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    )

  }

}
