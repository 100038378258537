import React, { Component } from 'react';
import { Grid, Drawer, Avatar, Typography, CardActionArea } from '@material-ui/core';
import '../css/Generic.css'
import { ConsultaFormDialog } from '../seres/ConsultaFormDialog';
import { VacinasFormDialog } from '../seres/VacinasFormDialog';
import { MedicacoesFormDialog } from '../seres/MedicacoesFormDialog';

export class SelecaoSerDrawer extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            ser: {},
            openConsultaDialog: false,
            openMedicacaoDialog: false,
            openVacinaDialog: false,
            loading: true
        }  
        this.handleSelect = this.handleSelect.bind(this)
    }

    handleSelect() {
        if (this.props.tipo === 'consulta')
            this.setState({openConsultaDialog: true})
        else if (this.props.tipo === 'medicacao')
            this.setState({openMedicacaoDialog: true})
        else if (this.props.tipo === 'vacina')
            this.setState({openVacinaDialog: true})
    }

    render() {
        return (
        <Drawer anchor="bottom" open={this.props.open} onClose={ () => this.props.onClose() }>
            
            {this.props.open?(
                <Grid container spacing={3} style={{padding: '10px', paddingTop: '20px'}}>  
                    <Grid item xs={12}>
                        <Typography variant="h6">Selecione alguém...</Typography>
                    </Grid>

                    <Grid item xs={12}>
                    {
                        this.props.lista.map( (e) => 
                            <CardActionArea onClick={ () => {this.setState({ser: e}, this.handleSelect)}} style={{padding: '5px'}}>
                                <Grid container spacing={3} style={{display: 'flex', alignItems: 'center'}}>
                                    <Grid item xs={2}>
                                        <Avatar src={e.avatar}/>
                                    </Grid>
                                    <Grid item xs={10}>
                                        {e.nome}
                                    </Grid>
                                </Grid>
                            </CardActionArea>
                        )
                    }
                    </Grid> 

                    <ConsultaFormDialog 
                        open={this.state.openConsultaDialog} 
                        onClose={()=>this.setState({openConsultaDialog: false},this.props.onClose)}
                        onSave={()=>this.setState({openConsultaDialog: false},this.props.onClose)}
                        paciente={this.state.ser} />

                    <VacinasFormDialog
                        open={this.state.openVacinaDialog}
                        onClose={()=>this.setState({openVacinaDialog: false},this.props.onClose)}
                        onSave={()=>this.setState({openVacinaDialog: false},this.props.onClose)}
                        paciente={this.state.ser}/>

                    <MedicacoesFormDialog
                        open={this.state.openMedicacaoDialog}
                        onClose={()=>this.setState({openMedicacaoDialog: false},this.props.onClose)}
                        onSave={()=>this.setState({openMedicacaoDialog: false},this.props.onClose)}
                        paciente={this.state.ser}/>

                </Grid>
            ):""}


            

        </Drawer>
        )
    }
}