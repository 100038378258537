import React, { Component } from 'react';
import { Grid, Typography, Slide, Dialog, Paper, Box, Button, Snackbar } from '@material-ui/core';
import { Toolbar, AppBar, IconButton, Avatar, Backdrop, CircularProgress } from '@material-ui/core';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { KeyboardArrowLeft } from '@material-ui/icons';
import MuiAlert from '@material-ui/lab/Alert';

import { DateTime } from "luxon";

import {Utils} from '../generic/Utils';
import axios from 'axios';
import '../css/Generic.css'
import { EventosFormDialog } from './EventosFormDialog';
import { EventosDetalhesDrawer } from './EventosDetalhesDrawer';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const baseUrl = Utils.getUrl()+"eventos/veiculo/";

export class EventosViewDialog extends Component {


    constructor(props) {
        super(props)

        this.state = {  
            lista: [],
            openDetalhe: false,
            openForm: false,
            loading: true,
            selecionada: {}
        }

        this.onRendered = this.onRendered.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    buscarLista() {
        let id = this.props.car._id  
        this.setState({loading: true})

        let tipo = this.props.tipo

        axios.get(baseUrl+tipo+"/"+id).then(
            resp => {
                if ( resp.status === 201 || resp.status === 200) {
                    let lista = resp.data
                    this.setState({lista:lista})
                }
                else console.log("Erro ao buscar")

                this.setState({loading: false})
            }
        ).catch( e => {
            console.log("Erro ao buscar "+e) 
            this.setState({loading: false})
        })
    }

    handleClose() {
        this.setState({openDetalhe:false})
        this.props.onClose()
    }

    onRendered() {
        this.buscarLista();
    }

    getTitle() {
        switch( this.props.tipo ) {
            case 'revisao':
                return "Revisões";
            case 'manutencao':
                return "Manutenções"
            default:
                return "Eventos"
        }
    }

    getTitleIcon() {
        switch( this.props.tipo ) {
            case 'revisao':
                return "../images/icons/revisao.png";
            case 'manutencao':
                return "../images/icons/manutencao.png"
            default:
                return "../images/icons/evento.png"
        }
    }

    render() {

        return (
            <Dialog fullScreen 
                open={this.props.open} 
                onClose={this.handleClose} 
                onRendered={this.onRendered}
                TransitionComponent={Transition}>

                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={this.handleClose}>
                            <KeyboardArrowLeft />
                        </IconButton>

                        <Typography component="h1" variant="h6" color="inherit" noWrap style={{flexGrow: 1}}>
                            {this.props.car.nome}
                        </Typography>
                    </Toolbar>
                </AppBar>
                
                <Grid container spacing={3} style={{backgroundColor: '#FAFAFA'}} >  

                    <Grid item xs={12} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#FFF'}}>  

                        <Avatar src={this.getTitleIcon()} 
                            style={{width: '60px', height: '60px', margin: '10px', marginTop: '30px',backgroundColor: "#91EFBD"}}/>
                            
                        <Typography variant='h6'>                                                        
                            {this.getTitle()}
                        </Typography> 

                    </Grid>
                    
                    <Grid item xs={12} style={{backgroundColor: '#FAFAFA', height: '100%'}}  alignItems="center">

                    { 
                        this.state.lista.length > 0 ? (    
                        <Timeline align="alternate">

                            { this.state.lista.map( (e) => 
                                <TimelineItem>
                                    <TimelineOppositeContent>
                                        <Typography variant="body2" color="textSecondary">
                                            {DateTime.fromISO(e.data).setLocale('pt').toFormat("dd/MM/yyyy")}
                                        </Typography>
                                    </TimelineOppositeContent>

                                    <TimelineSeparator>
                                        <TimelineDot color="primary" />
                                        <TimelineConnector />
                                    </TimelineSeparator>

                                    <TimelineContent>
                                        <Paper elevation={3} style={{padding: '10px', textAlign: 'left', cursor: 'pointer'}} 
                                            onClick={() => this.setState({selecionada: e, openDetalhe: true})}>
                                            <Typography variant="body1">
                                                <b>{e.nome}</b>
                                            </Typography>
                                            <Typography variant="subtitle2" color="textSecondary">
                                                {e.local?e.local:""}
                                            </Typography>  
                                        </Paper>
                                    </TimelineContent>
                                </TimelineItem>
                            )}
                        </Timeline>
                        ) : 
                        (
                            <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center'}}>
                                <Avatar src="../images/icons/empty.png" style={{width: '150px', height: '150px', textAlign: 'center', marginTop: '50px'}}/>
                                <Typography variant="subtitle">
                                    Nada encontrado por aqui!
                                </Typography>
                            </Box>
                        )
                    }

                    </Grid>
                </Grid>

                <AppBar position="fixed" style={{top: 'auto', bottom: 0, backgroundColor: 'white'}}>
                    <Toolbar style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <Button color="primary" variant='contained' onClick={()=>this.setState({openForm: true})}>
                            Nova
                        </Button>
                    </Toolbar>
                </AppBar>      

                <EventosFormDialog
                    tipo={this.props.tipo}
                    open={this.state.openForm}
                    onClose={()=>this.setState({openForm:false})} 
                    onSave={()=>{
                        this.setState({successMessage: true, openForm:false})
                        this.buscarLista()
                    }}
                    car={this.props.car}/>

                <EventosDetalhesDrawer
                    open={this.state.openDetalhe}
                    onClose={()=>this.setState({openDetalhe:false})}
                    onDelete={()=>{
                        this.setState({deleteMessage: true, openDetalhe:false})
                        this.buscarLista()
                    }}
                    car={this.state.selecionada} />

                <Snackbar open={this.state.successMessage} autoHideDuration={6000} 
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => this.setState({successMessage:false})}>
                    <MuiAlert severity="success" elevation={6} variant="filled" onClose={ () => this.setState({successMessage:false})}>
                        Cadastro realizado com sucesso!
                    </MuiAlert>
                </Snackbar>

                <Snackbar open={this.state.deleteMessage} autoHideDuration={6000} 
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => this.setState({deleteMessage:false})}>
                    <MuiAlert severity="success" elevation={6} variant="filled" onClose={ () => this.setState({deleteMessage:false})}>
                        Registro excluído com sucesso!
                    </MuiAlert>
                </Snackbar>

                <Backdrop
                    style={{ color: '#fff', zIndex: 10000 }}
                    open={this.state.loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Dialog>
        )
    }

}