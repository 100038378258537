import React from 'react';
import ReactDOM from 'react-dom';
import Template from './components/Template';
import { HashRouter } from 'react-router-dom';


function App() {
  return (
    <HashRouter>
        <Template/>
    </HashRouter>
  );
}

ReactDOM.render(<App />, document.querySelector('#root'));