import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Backdrop from '@material-ui/core/Backdrop'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Snackbar from '@material-ui/core/Snackbar'
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import AccountCircle from '@material-ui/icons/AccountCircle';
import CircularProgress from '@material-ui/core/CircularProgress'
import Popper from '@material-ui/core/Popper';
import MuiAlert from '@material-ui/lab/Alert';

import {Dashboard} from './dashboard/Dashboard'

import {Route} from 'react-router-dom';
import SignIn from './SignIn';
import { Paper } from '@material-ui/core';
import { Perfil } from './usuario/Perfil';
import { Utils } from './generic/Utils';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="#">        
      {Utils.getAppName()}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    backgroundColor: 'white !important',
    minWidth: '250px'
  },
  fixedHeight: {
    height: 265,
  },
}));


export default function Template() {
  
  const classes = useStyles();
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [openUser, setOpenUser] = React.useState(false);
  const [signed, setSigned] = React.useState(false);

  const handleSignSucess = () => {
    console.log("Login success")

    
    setError(false)
    setLoading(false)
    setSigned(true)
    
  }

  const handleSignError = () => {
    console.log("Login Error")
    setLoading(false)
    setSigned(false)
    setError(true)
  }

  if ( signed )

    return (

      <div className={classes.root}>
        <CssBaseline />

        <AppBar position="absolute" className={clsx(classes.appBar, false && classes.appBarShift)}>
          
          <Toolbar className={classes.toolbar}>

            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
              {Utils.getAppName()}
            </Typography>

            <IconButton id="icone" color="inherit" onClick={ () => {setOpenUser(!openUser)}}>
              <AccountCircle />

              <Popper open={openUser} anchorEl={document.getElementById('icone')} placement={'bottom-end'} style={{zIndex: 10000}}>
                <Paper className={classes.paper}>
                  <Box pt={3}/>
                  <Typography variant="body1" noWrap>
                    {sessionStorage.getItem('userName')}
                  </Typography>

                  <Box pt={3}/>
                  <Divider />
                  <Box pt={3}/>
                  
                  <Link color="primary" href="/#/">
                    <Typography variant="body2" noWrap><b>Configurações</b></Typography>
                  </Link>
                  
                  <Box pt={3}/>
                    <Divider />
                  <Box pt={3}/>

                  <Link color="primary" href="/#/" onClick={ () => {setSigned(false)}}>
                    <Typography variant="body2" noWrap><b>Sair</b></Typography>
                  </Link>
                </Paper>
              </Popper>

            </IconButton> 
          </Toolbar>
        </AppBar>
        
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            
            <Route path="/" exact component={Dashboard} />
            <Route path="/perfil" component={Perfil} />

            <Box pt={4}>
              <Copyright />
            </Box>
          </Container>
        </main>
        
        <Backdrop
            style={{ color: '#fff', zIndex: 10000 }}
            open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    )
  else
    return (
      <div>
        <SignIn onSucess={handleSignSucess} onError={handleSignError} />

        <Snackbar open={error} autoHideDuration={6000} 
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
            onClose={() => setError(false)}>
            <MuiAlert severity="error" elevation={6} variant="filled" 
                onClose={ () => setError(false)}>
                Login inválido!
            </MuiAlert>
        </Snackbar>
      </div>
    )
}