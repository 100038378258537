import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import PessoaSwiperCard from './PessoaSwiperCard';

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import CarSwiperCard from './CarSwiperCard';

export default function DefaultSwiper(props) {

  return (      
    <Swiper
        style={{padding: '10px'}}
        slidesPerView={2}
        spaceBetween={5}
        freeMode={true}>
        {
          props.tipo === 'pessoa'?
            props.lista.map( (e) =>
                <SwiperSlide> 
                    <PessoaSwiperCard ser={e} onRefresh={props.onRefresh} isPet={props.isPet}/>
                </SwiperSlide>
            )
            :
            props.lista.map( (e) =>
                <SwiperSlide> 
                    <CarSwiperCard car={e} onRefresh={props.onRefresh} />
                </SwiperSlide>
            )
        }
    </Swiper>        
  )
  
}