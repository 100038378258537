import React, { Component } from 'react';
import { Grid, Typography, Drawer, Avatar, Backdrop, CircularProgress, Fab } from '@material-ui/core';

import {Utils} from '../generic/Utils';
import axios from 'axios';
import '../css/Generic.css'

export class CarrosAvatarDrawner extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            avatar: '../images/icons/cars/car.png',
            cor: '#F0EFEE',
            saving: false
        }  

        this.save = this.save.bind(this)
        this.handleAvatar = this.handleAvatar.bind(this)
        this.handleCor = this.handleCor.bind(this)
    }
    
    save() {
        this.setState({saving: true})

        let cadastro = {...this.props.car}

        cadastro.avatar = this.state.avatar
        cadastro.cor = this.state.cor

        axios.put(Utils.getUrl()+"carros/",{...cadastro}).then(
            resp => {
                if ( resp.status === 201 || resp.status === 200) {  
                    this.props.onSave()
                    this.setState({saving: false})
                }
                else {
                    this.setState({saving: false})
                }
            }
        ).catch( e => {      
            this.setState({saving: false})
        })
    }

    handleAvatar(item) {
        this.setState({avatar: item})
    }

    handleCor(item) {
        this.setState({cor: item})
    }

    componentDidMount() {
        this.setState({
            avatar: this.props.car.avatar,
            cor: this.props.car.cor
        })
    }

    render() {
        return (
        <Drawer anchor="bottom" open={this.props.open} onClose={() => this.props.onClose()}>
            
            {this.props.open?(
                <Grid container spacing={3} style={{padding: '15px'}}>  
                    <Grid item xs={12}>
                        <Typography variant='h6'>
                            Escolha uma figurinha e uma cor...
                        </Typography>
                    </Grid>    
                    <Grid item xs={12} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}> 
                        <Avatar src={this.state.avatar}
                            style={{width: '100px', 
                                height: '100px', 
                                backgroundColor: this.state.cor,
                                textAlign:'center'}}/>
                    </Grid>                     
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems="center"> 
                            {Utils.getCarAvatares().map((item) => (
                                <Grid item xs={4} sm={2} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <Avatar src={item} 
                                        onClick={() => this.handleAvatar(item)} 
                                        style={{width: '100px', height: '100px', textAlign:'center', cursor: 'pointer'}}/>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>                        
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems="center"> 
                            {Utils.getCarColors().map((item) => (
                                <Grid item xs={2}  style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <Avatar 
                                        src="../images/none.png"
                                        onClick={() => this.handleCor(item)} 
                                        style={{width: '50px', height: '50px', textAlign:'center', cursor: 'pointer', backgroundColor: item}}/>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                                            
                    <Grid item xs={12}>  
                        <div style={{textAlign:'center'}}>                        
                            <Fab variant="extended" color="primary" style={{width: '90%'}} onClick={this.save}>
                                Salvar
                            </Fab> 
                        </div>   
                    </Grid>                                                      
                </Grid>
            ):""}

            <Backdrop
                style={{ color: '#fff', zIndex: 10000 }}
                open={this.state.saving}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </Drawer>
        )
    }
}