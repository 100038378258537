import React, { Component } from 'react';
import { Card, CardMedia, CardContent, CardActionArea, Typography } from '@material-ui/core';
import { SeresViewDialog } from '../seres/SeresViewDialog';

import { DateTime } from "luxon";

export default class PessoaSwiperCard extends Component {

    constructor(props) {
        super(props)

        this.state = {
            openPessoaViewDialog: false,
            pessoaSelecionada: {}
        }  
    }
        
  render() {
      return (      
        <Card style={{maxWidth: '150px'}} square={false} elevation={1} variant="elevation">
            <CardActionArea onClick={() => this.setState({openPessoaViewDialog:true})}>  
                <CardMedia component="img" src={this.props.ser.avatar} />
                <CardContent>
                    <Typography variant='body1'>
                        <b>{this.props.ser.nome.split(" ")[0]}</b>
                    </Typography>
                    <Typography variant='caption'>
                        {parseInt(Math.abs(DateTime.fromISO(this.props.ser.nascimento).diffNow('years').years))} Anos
                    </Typography>
                </CardContent>
            </CardActionArea>
            
            
            <SeresViewDialog
                isPet={this.props.isPet}
                open={this.state.openPessoaViewDialog} 
                onClose={()=>this.setState({openPessoaViewDialog: false})}
                onRefresh={this.props.onRefresh}
                pessoa={this.props.ser}/>
        </Card>  
      )
    }        

}