import React, { Component } from 'react';
import { Typography, Slide, Dialog, Grid } from '@material-ui/core';
import { Toolbar, AppBar, IconButton, Button, Avatar } from '@material-ui/core';
import { Snackbar, TextField, Backdrop, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';

import {Utils} from '../generic/Utils';
import axios from 'axios';
import '../css/Generic.css'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export class EventosFormDialog extends Component {
    
    constructor(props) {
        super(props)

        this.state = {            
            cadastro: {
                nome: '',
                local: '',
                data: new Date(),
                valor: 0,
                quilometragem: 0,
                observacoes: ''
            },
            loading: false,
            lista: [],
            erroNome: false,
            showErroMessage:false,   
            fatalErroMessage:false
        }

        this.handleClose = this.handleClose.bind(this)
        this.handleSave = this.handleSave.bind(this)
    }

    async handleSave() {
        let cadastro = {...this.state.cadastro}
        cadastro.veiculo = this.props.car
        cadastro.tipo = this.props.tipo

        if ( cadastro.nome.trim() === '' ) {
            this.setState( {
                showErroMessage: true,
                erroNome: true
            })
            return
        }

        this.setState({
            saving: true, 
            showErroMessage:false,   
            fatalErroMessage:false
        })

        axios.post(Utils.getUrl()+"eventos/",{...cadastro}).then(
            resp => {
                if ( resp.status === 201 || resp.status === 200) { 
                    this.setState( {saving: false})                     
                    this.handleClose(true)
                }
                else {
                    console.log('Erro ao cadastrar Revisao')
                    this.setState({fatalErroMessage:true, saving: false})
                }
            }
        ).catch( e => {    
            console.log('Erro ao cadastrar Revisao')
            console.log(e)  
            this.setState({fatalErroMessage:true, saving: false})
        })
    }

    handleClose(saving) {
        this.setState( {            
            cadastro: {
                nome: '',
                local: '',
                data: new Date(),
                tipo: 'revisao',
                valor: 0,
                quilometragem: 0,
                observacoes: ''
            },
            loading: false,
            erroNome: false,
            showErroMessage:false,   
            fatalErroMessage:false
        })

        if (saving) {
            this.props.onSave()
        }
        else this.props.onClose()
    }

    getTitle() {
        switch( this.props.tipo ) {
            case 'revisao':
                return "Nova Revisão";
            case 'manutencao':
                return "Nova Manutenção"
            default:
                return "Novo Evento"
        }
    }

    render() {
        return (
            <Dialog fullScreen 
                open={this.props.open} 
                onClose={this.handleClose} 
                TransitionComponent={Transition}>

                <AppBar position="static">
                    <Toolbar>
                        <Typography component="h1" variant="h6" color="inherit" noWrap style={{flexGrow: 1}}>
                            {this.getTitle()}
                        </Typography>

                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => this.handleClose(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <Grid container spacing={3} style={{backgroundColor: '#FAFAFA'}} > 
                    
                    <Grid item xs={2} style={{backgroundColor: '#FFF', marginTop: '10px'}}>  
                        <Avatar src={this.props.car.avatar} 
                                style={{width: '30px', height: '30px', margin: '10px', backgroundColor: this.props.car.cor}}/>  
                    </Grid>
                    <Grid item xs={10} style={{display: 'flex', flexDirection: 'column', 
                        justifyContent: 'center', backgroundColor: '#FFF', marginTop: '10px'}}>      
                        <Typography variant='subtitle2' noWrap>                                                    
                            {this.props.car.nome}
                        </Typography> 

                    </Grid>

                    <Grid item xs={12} style={{backgroundColor: '#FAFAFA', height: '100%', margin: '15px'}}>

                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <TextField id="nome" 
                                    fullWidth
                                    required
                                    error={this.state.erroNome}
                                    label="Nome"  
                                    variant='outlined' 
                                    value={this.state.cadastro.nome}
                                    onChange={ (e) => 
                                        this.setState( { cadastro: {...this.state.cadastro, 
                                            nome: e.target.value} } ) }/>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField id="local" 
                                    fullWidth
                                    label="Local"  
                                    variant='outlined' 
                                    value={this.state.cadastro.local}
                                    onChange={ (e) => 
                                        this.setState( { cadastro: {...this.state.cadastro, 
                                            local: e.target.value} } ) }/>
                            </Grid>

                            <Grid item xs={12}>                        
                                <MuiPickersUtilsProvider utils={LuxonUtils}>
                                    <KeyboardDatePicker
                                        id="data"
                                        fullWidth
                                        format="dd/MM/yyyy"
                                        label="Data" 
                                        inputVariant='outlined'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}     
                                        value={this.state.cadastro.data}
                                        onChange={ (e) => 
                                            this.setState( { cadastro: {...this.state.cadastro, 
                                                data: new Date(e) }}) }
                                    />   
                                </MuiPickersUtilsProvider>                        
                            </Grid>
                            
                            <Grid item xs={12} sm={6}>
                                <TextField id="valor" 
                                    fullWidth
                                    label="Valor"  
                                    variant='outlined' 
                                    value={this.state.cadastro.valor}
                                    onChange={ (e) => 
                                        this.setState( { cadastro: {...this.state.cadastro, 
                                            valor: e.target.value} } ) }/>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField id="quilometragem" 
                                    fullWidth
                                    label="Quilometragem"  
                                    variant='outlined' 
                                    value={this.state.cadastro.quilometragem}
                                    onChange={ (e) => 
                                        this.setState( { cadastro: {...this.state.cadastro, 
                                            quilometragem: e.target.value} } ) }/>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField id="observacoes" 
                                    fullWidth
                                    multiline
                                    rows={4}
                                    label="Observações"  
                                    variant='outlined' 
                                    value={this.state.cadastro.observacoes}
                                    onChange={ (e) => 
                                        this.setState( { cadastro: {...this.state.cadastro, 
                                            observacoes: e.target.value }} ) }/>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

                <AppBar position="fixed" style={{top: 'auto', bottom: 0, backgroundColor: 'white'}}>
                    <Toolbar style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <Button color="primary" variant='contained' onClick={this.handleSave}>
                            Salvar
                        </Button>
                    </Toolbar>
                </AppBar> 

                <Snackbar open={this.state.showErroMessage} autoHideDuration={6000} 
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => this.setState({showErroMessage:false})}>
                    <MuiAlert severity="error" elevation={6} variant="filled" onClose={ () => this.setState({showErroMessage:false})}>
                        Por favor, verifique os campos e tente novamente.
                    </MuiAlert>
                </Snackbar>

                <Snackbar open={this.state.fatalErroMessage} autoHideDuration={6000} 
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => this.setState({fatalErroMessage:false})}>
                    <MuiAlert severity="error" elevation={6} variant="filled" onClose={ () => this.setState({fatalErroMessage:false})}>
                        Erro ao realizar cadastro. Por favor, tente novamente mais tarde.
                    </MuiAlert>
                </Snackbar>
                
                <Backdrop
                    style={{ color: '#fff', zIndex: 10000 }}
                    open={this.state.saving || this.state.loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Dialog>
        )
    }
}