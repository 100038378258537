import React, { Component } from 'react';
import { Grid, Button, Typography, Slide, Dialog, Paper, Popper, Box, Link } from '@material-ui/core';
import { Toolbar, AppBar, IconButton, Avatar, Backdrop, CircularProgress } from '@material-ui/core';
import { Card, CardActionArea, CardMedia, CardContent, Snackbar } from '@material-ui/core';
import { KeyboardArrowLeft } from '@material-ui/icons';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import MuiAlert from '@material-ui/lab/Alert';

import { DateTime } from "luxon";

import '../css/Generic.css'
import { ConsultasViewDialog } from './ConsultasViewDialog';
import { MedicacoesViewDialog } from './MedicacoesViewDialog';
import { SeresEditDialog } from './SeresEditDialog';

import {Utils} from '../generic/Utils';
import axios from 'axios';
import { SeresAvatarDrawner } from './SeresAvatarDrawner';
import { VacinasViewDialog } from './VacinasViewDialog';
import { ConfirmDialog } from '../util/ConfirmDialog';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export class SeresViewDialog extends Component {

    constructor(props) {
        super(props)

        this.state = { 
            pessoa: {
                nome: '',
                sexo: '',
                nascimento: ''
            },
            openAvatar: false,
            openVacinasViewDialog: false,
            openConsultaViewDialog: false,
            openMedicacoesViewDialog: false
        }

        this.handleSave = this.handleSave.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
    }

    handleClose() {
        this.props.onClose()
    }

    componentDidMount() {
        this.setState({pessoa: this.props.pessoa})
    }

    handleDelete() {
        //Deletar 
        axios.delete(Utils.getUrl()+"seres/"+this.props.pessoa._id).then(
            resp => {
                if ( resp.status === 201 || resp.status === 200) {
                    
                    this.props.onClose()
                    this.props.onRefresh()

                    this.setState({pessoa:{}, openEditDialog: false, openAvatar: false})
                }
                else console.log("Erro ao Deletar")
            }
        ).catch( e => {
            console.log("Erro ao Deletar "+e) 
        })
    }

    handleSave() {
        //Atualizar pessoas
        axios.get(Utils.getUrl()+"seres/pessoa/"+this.props.pessoa._id).then(
            resp => {
                if ( resp.status === 201 || resp.status === 200) {
                    let p = resp.data
                    this.props.onRefresh()
                    this.setState({pessoa:p, successMessage: true, openEditDialog: false, openAvatar: false})
                }
                else console.log("Erro ao buscar")
            }
        ).catch( e => {
            console.log("Erro ao buscar "+e) 
        })
    }

    montarMenu() {
        return (
        <Grid container style={{padding: '10px'}}> 
        
            <Grid item xs={4} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Card style={{maxWidth: '100px'}} square={false} elevation={1} variant="elevation">
                    <CardActionArea onClick={()=>this.setState({openConsultaViewDialog: true})}>      
                        <CardMedia component="img" src="../images/icons/medic.png" style={{backgroundColor: "#F6F09D"}}/>                                     
                        <CardContent style={{display: 'flex', justifyContent: 'center', paddingTop: '10px', paddingBottom: '10px'}}>
                            <Typography variant='body2'>                                                        
                                Consultas
                            </Typography>                                                    
                        </CardContent> 
                    </CardActionArea>
                </Card> 
            </Grid>

            <Grid item xs={4} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <Card style={{maxWidth: '100px'}} square={false} elevation={1} variant="elevation">
                    <CardActionArea onClick={()=>this.setState({openMedicacoesViewDialog: true})}>      
                        <CardMedia component="img" src="../images/icons/medication.png" style={{backgroundColor: "#A6AEF6"}}/>                                     
                        <CardContent style={{display: 'flex', justifyContent: 'center', paddingTop: '10px', paddingBottom: '10px'}}>
                            <Typography variant='body2'>                                                      
                                Medicações
                            </Typography>                                                    
                        </CardContent> 
                    </CardActionArea>
                </Card> 
            </Grid>

            <Grid item xs={4} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Card style={{maxWidth: '100px'}} square={false} elevation={1} variant="elevation">
                    <CardActionArea onClick={()=>this.setState({openVacinasViewDialog: true})}>      
                        <CardMedia component="img" src="../images/icons/vacine.png" style={{backgroundColor: "#91EFBD"}}/>                                     
                        <CardContent style={{display: 'flex', justifyContent: 'center', paddingTop: '10px', paddingBottom: '10px'}}>
                            <Typography variant='body2'>                                                        
                                Vacinas
                            </Typography>                                                    
                        </CardContent> 
                    </CardActionArea>
                </Card> 
            </Grid>

        </Grid> 
        )
    }

    render() {

        return (
            <Dialog fullScreen 
                open={this.props.open} 
                onClose={this.handleClose} 
                onRendered={this.onRendered}
                TransitionComponent={Transition}>

                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={this.handleClose}>
                            <KeyboardArrowLeft />
                        </IconButton>

                        <Typography component="h1" variant="h6" color="inherit" noWrap style={{flexGrow: 1}}>
                            {this.state.pessoa.nome}
                        </Typography>

                        <IconButton color="inherit" id="dotsIcon"  onClick={ () => {this.setState({openMenu: !this.state.openMenu})}}>
                            <MoreVertIcon />
                            <Popper open={this.state.openMenu} anchorEl={document.getElementById('dotsIcon')} placement={'bottom-end'} style={{zIndex: 10000}}>
                                <Paper style={{padding: '10px', display: 'flex', flexDirection: 'column', 
                                                backgroundColor: 'white !important', minWidth: '200px'}}>
                                    <Box pt={1}/>
                                    
                                    <Link color="primary" onClick={() => {this.setState({openEditDialog: true})}}>
                                        <Typography variant="body2" noWrap>
                                            Editar
                                        </Typography>
                                    </Link>
                                    
                                    <Box pt={3}/>
                                    <Link color="primary" onClick={ () => this.setState({confirmDelete: true}) } >
                                        <Typography variant="body2" noWrap>
                                            Excluir
                                        </Typography>
                                    </Link>
                                </Paper>
                            </Popper>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                
                <Grid container spacing={3} >  

                    <Grid item xs={12} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>                            
                        <Avatar src={this.state.pessoa.avatar} 
                            style={{width: '100px', height: '100px', margin: '10px', marginTop: '30px'}}
                            onClick={() => this.setState({openAvatar: true})}/>
                        
                        <Button variant="outlined"
                            size="small"
                            startIcon={<EditIcon />}
                            onClick={() => this.setState({openEditDialog: true})}>
                                Editar
                        </Button>
                    </Grid>
                    
                    <Grid item xs={12} style={{backgroundColor: '#FAFAFA'}}  alignItems="center">

                        
                        {this.montarMenu()}

                        <Grid container spacing={3} style={{padding: '20px'}}> 
                            <Grid item xs={12}>
                                <Typography variant="body2" style={{paddingBottom: '20px'}}>
                                    <b>Informações Gerais</b>
                                </Typography>
                                
                                <Paper>
                                    <Grid container spacing={3} style={{padding: '20px'}}> 
                                        <Grid item xs={4} sm={2}>
                                            <b>Nome</b>
                                        </Grid>
                                        <Grid item xs={8} sm={10}>
                                            {this.state.pessoa.nome}
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            <b>Sexo</b>
                                        </Grid>
                                        <Grid item xs={8} sm={10}>
                                            {this.state.pessoa.sexo==='f'?'Feminino':(this.state.pessoa.sexo==='m'?'Masculino':'Não Informado')}
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            <b>Idade</b>
                                        </Grid>
                                        <Grid item xs={8} sm={10}>
                                            {parseInt(Math.abs(DateTime.fromISO(this.state.pessoa.nascimento).diffNow('years').years))} Anos
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            <b>Nascimento</b>
                                        </Grid>
                                        <Grid item xs={8} sm={10}>
                                            {DateTime.fromISO(this.state.pessoa.nascimento).setLocale('pt').toLocaleString()} 
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            
                            <Grid item xs={12}>

                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>

                <ConsultasViewDialog
                    open={this.state.openConsultaViewDialog} 
                    onClose={()=>this.setState({openConsultaViewDialog: false})}
                    pessoa={this.state.pessoa}/>

                <MedicacoesViewDialog
                    open={this.state.openMedicacoesViewDialog} 
                    onClose={()=>this.setState({openMedicacoesViewDialog: false})}
                    pessoa={this.state.pessoa}/>

                <SeresEditDialog
                    open={this.state.openEditDialog} 
                    onClose={()=>this.setState({openEditDialog: false})}
                    onSave={this.handleSave}
                    pessoa={this.state.pessoa} />

                <VacinasViewDialog
                    open={this.state.openVacinasViewDialog} 
                    onClose={()=>this.setState({openVacinasViewDialog: false})}
                    onSave={this.handleSave}
                    pessoa={this.state.pessoa} />

                <Snackbar open={this.state.successMessage} autoHideDuration={6000} 
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => this.setState({successMessage:false})}>
                    <MuiAlert severity="success" elevation={6} variant="filled" onClose={ () => this.setState({successMessage:false})}>
                        Atualização realizada com sucesso!
                    </MuiAlert>
                </Snackbar>
                
                <ConfirmDialog open={this.state.confirmDelete}
                    handleClose={ () => this.setState({confirmDelete: false}) }
                    handleOk={this.handleDelete}
                    message="Você deseja realmente excluir? Essa operação não pode ser desfeita!"
                />

                <SeresAvatarDrawner
                        open={this.state.openAvatar} 
                        pessoa={this.state.pessoa}
                        onClose={()=>this.setState({openAvatar: false})}
                        onSave={() => {
                            this.handleSave()
                        }}/>

                <Backdrop
                    style={{ color: '#fff', zIndex: 10000 }}
                    open={this.state.saving}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Dialog>
        )
    }

}