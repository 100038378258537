import React, { Component } from 'react';
import { Grid, Typography, Slide, Dialog } from '@material-ui/core';
import {Toolbar,IconButton, Button, Avatar } from '@material-ui/core';
import { TextField, AppBar, Snackbar, Backdrop, CircularProgress } from '@material-ui/core';
import { KeyboardArrowLeft } from '@material-ui/icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MuiAlert from '@material-ui/lab/Alert';
import LuxonUtils from '@date-io/luxon';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import {Utils} from '../generic/Utils';
import axios from 'axios';
import '../css/Generic.css'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export class SeresEditDialog extends Component {

    constructor(props) {
        super(props)

        this.state = {
            cadastro: {
                avatar: '../images/icons/default.png',
                nome: '',
                nascimento: new Date(),
                sexo: '',
                observacoes: ''
            },
            openAvatar: false,
            showNomeMessage: false,
            saving: false
        }

        this.handleSave = this.handleSave.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    handleClose() {
        this.setState({
            cadastro: {
                avatar: this.props.pessoa.avatar,
                nome: this.props.pessoa.nome,
                nascimento: this.props.pessoa.nascimento,
                sexo: this.props.pessoa.sexo,
                observacoes: this.props.pessoa.observacoes
            }
        })

        this.props.onClose()
    }

    handleSave() {
        let cadastro = {...this.state.cadastro}

        if ( cadastro.nome.trim() === '') {
            this.setState({showNomeMessage: true})
            return
        }

        this.setState({saving: true, 
            showNomeMessage: false
        })

        cadastro._id = this.props.pessoa._id
        cadastro.userId = sessionStorage.getItem('userId')
        cadastro.tipo = 'pessoa'

        axios.put(Utils.getUrl()+"seres/",{...cadastro}).then(
            resp => {
                if ( resp.status === 201 || resp.status === 200) {   

                    this.setState( {
                        showNomeMessage: false,
                        saving: false
                    })  

                    this.props.onSave()
                }
                else {
                    this.setState({saving: false})
                }
            }
        ).catch( e => {      
            this.setState({saving: false})
        })
    }

    componentDidMount() {
        this.setState({
            cadastro: {
                avatar: this.props.pessoa.avatar,
                nome: this.props.pessoa.nome,
                nascimento: this.props.pessoa.nascimento,
                sexo: this.props.pessoa.sexo,
                observacoes: this.props.pessoa.observacoes
            }
        })
    }

    render() {

        return (
            <Dialog fullScreen 
                open={this.props.open} 
                onClose={this.handleClose} 
                onRender={this.onRender}
                TransitionComponent={Transition}>

                    <AppBar position="static" style={{minHeight: '50px', marginBottom: '15px'}}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={this.handleClose}>
                                <KeyboardArrowLeft />
                            </IconButton>

                            <Typography component="h1" variant="h6" color="inherit" noWrap>
                                {this.props.pessoa.nome}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <Grid container spacing={3} alignItems="center" style={{padding: '15px'}}>

                        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                            <Avatar src={this.state.cadastro.avatar} 
                                style={{width: '100px', height: '100px'}}
                                alt={this.state.cadastro.nome}/>
                        </Grid>
                        
                        <Grid item xs={12}>
                            <TextField id="nome" 
                                required
                                fullWidth
                                error={this.state.showNomeMessage}
                                label="Nome"  
                                variant='outlined' 
                                value={this.state.cadastro.nome}
                                onChange={ (e) => 
                                    this.setState( { cadastro: {...this.state.cadastro, 
                                    nome: e.target.value} } ) }/>
                        </Grid> 

                        <Grid item xs={12}>                        
                            <MuiPickersUtilsProvider utils={LuxonUtils}>
                                <KeyboardDatePicker
                                    id="data"
                                    required
                                    fullWidth
                                    format="dd/MM/yyyy"
                                    label="Data de Nascimento" 
                                    inputVariant='outlined'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}     
                                    value={this.state.cadastro.nascimento}
                                    onChange={ (e) => 
                                        this.setState( { cadastro: {...this.state.cadastro, 
                                        nascimento: new Date(e)} } ) }
                                />   
                            </MuiPickersUtilsProvider>                        
                        </Grid>

                        <Grid item xs={12}>
                            <Select
                                labelId="sexo-label"
                                fullWidth
                                variant='outlined'
                                value={this.state.cadastro.sexo}
                                onChange={ (e) => {
                                    console.log(e.target.value)
                                        this.setState({cadastro: {...this.state.cadastro,
                                            sexo: e.target.value}})  
                                    }
                                }>
                                <MenuItem value={'m'}>
                                    Masculino
                                </MenuItem>
                                <MenuItem value={'f'}>
                                    Feminino
                                </MenuItem>
                            </Select>
                        </Grid>
                        
                        <Grid item xs={12}>
                            <TextField id="observacoes" 
                                fullWidth
                                multiline
                                rows={4}
                                label="Observações"  
                                variant='outlined' 
                                value={this.state.cadastro.observacoes}
                                onChange={ (e) => 
                                    this.setState( { cadastro: {...this.state.cadastro, 
                                    observacoes: e.target.value} } ) }/>
                        </Grid>

                    </Grid>

                    <AppBar position="fixed" style={{top: 'auto', bottom: 0, backgroundColor: 'white'}}>
                        <Toolbar style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <Button color="primary" variant='contained' onClick={this.handleSave}>
                                Salvar
                            </Button>
                        </Toolbar>
                    </AppBar> 

                    <Snackbar open={this.state.showNomeMessage} autoHideDuration={6000} 
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => this.setState({showNomeMessage:false})}>
                        <MuiAlert severity="error" elevation={6} variant="filled" onClose={ () => this.setState({showNomeMessage:false})}>
                            Por favor, verifique os campos e tente novamente.
                        </MuiAlert>
                    </Snackbar>

                    <Backdrop
                        style={{ color: '#fff', zIndex: 10000 }}
                        open={this.state.saving}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
            </Dialog>
        )
    }

}