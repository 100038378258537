import React, { Component } from 'react';
import { Grid, Typography, Drawer, Snackbar, Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import MuiAlert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

import {Utils} from './generic/Utils';
import axios from 'axios';
import md5 from 'md5'
import './css/Generic.css'

export class CadastroDrawer extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            erroDuplicidade: false,
            errNome: false,
            errEmail: false,
            errSenha: false
        }  

        this.handleSave = this.handleSave.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    handleSave() {
        this.setState({errEmail: false, errNome: false, errSenha: false})
        this.setState({loading: true})

        let email = document.getElementById('emailCadastro').value.trim().toLowerCase()
        let nome = document.getElementById('nomeCadastro').value.trim()
        let pass = md5(document.getElementById('passwordCadastro').value.trim())

        if ( nome === '' ) {
            this.setState({errNome: true, loading: false})
            return
        }
        if ( email === '' ) {
            this.setState({errEmail: true, loading: false})
            return
        }
        if ( document.getElementById('passwordCadastro').value.trim() === '' ) {
            this.setState({errSenha: true, loading: false})
            return
        }

        let user = {
            nome: nome,
            email: email,
            password: pass
        }

        axios.post(Utils.getUrl()+'user/',{...user}).then(
            resp => {
                if ( resp.status === 200 ) {     
                    this.setState({loading: false})
                    this.props.onSucess()
                }
                else {
                    this.setState({loading: false})
                    this.props.onError()                      
                }                        
            }

        ).catch( e => {
            console.log(e)
            if ( e.response.status === 409) {
                console.log("Duplicado")
                this.setState({loading: false, erroDuplicidade: true})
            }
            this.setState({loading: false})
        })
    }

    handleClose() {
        this.setState({            
            loading: false,
            erroDuplicidade: false,
            errNome: false,
            errEmail: false,
            errSenha: false})
        this.props.onClose()
    }
    
    render() {
        return (
        <Drawer anchor="bottom" open={this.props.open} onClose={this.handleClose}>

            {this.props.open?(
                <Grid container spacing={3} style={{padding: '30px'}}>  

                    <Grid item xs={12}>     
                        <Typography variant="h6">
                            Cadastre-se!
                        </Typography>
                    </Grid>  

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="nomeCadastro"
                            label="Seu nome"
                            autoFocus
                            error={this.state.errNome}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="emailCadastro"
                            label="Seu melhor Email"
                            error={this.state.errEmail}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Sua Senha"
                            type="password"
                            id="passwordCadastro"
                            error={this.state.errSenha}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={this.handleSave}>
                            Cadastrar
                        </Button>
                    </Grid>
                </Grid>
            ):""}

            <Snackbar open={this.state.erroDuplicidade} autoHideDuration={6000} 
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
                onClose={() => this.setState({erroDuplicidade:false})}>
                <MuiAlert severity="error" elevation={6} variant="filled" onClose={ () => this.setState({erroDuplicidade: false})}>
                    O Email informado já está em uso!
                </MuiAlert>
            </Snackbar>

            <Snackbar open={this.state.errEmail || this.state.errNome || this.state.errSenha} autoHideDuration={6000} 
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
                onClose={() => this.setState({erroDuplicidade:false})}>
                <MuiAlert severity="error" elevation={6} variant="filled" 
                    onClose={ () => this.setState({errEmail: false, errNome: false, errSenha: false})}>
                    Todos os dados são obrigatórios!
                </MuiAlert>
            </Snackbar>

            <Backdrop
                style={{ color: '#fff', zIndex: 10000 }}
                open={this.state.loading}>
                <CircularProgress color="inherit" />
            </Backdrop>


        </Drawer>
        )
    }
}