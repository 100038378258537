import React, { Component } from 'react';
import { Typography, Slide, Dialog, Grid } from '@material-ui/core';
import { Toolbar, AppBar, IconButton, Button, Avatar } from '@material-ui/core';
import { Snackbar, TextField, Backdrop, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';

import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';

import {Utils} from '../generic/Utils';
import axios from 'axios';
import '../css/Generic.css'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export class MedicacoesFormDialog extends Component {
    
    constructor(props) {
        super(props)

        this.state = {            
            cadastro: {
                nome: '',
                posologia: '',
                data: new Date(),
                fim: new Date(),
                observacoes: ''
            },
            loading: false,
            listaMedicacoes: [],
            errorMedicacao: false,
            showErroMessage:false,   
            fatalErroMessage:false
        }

        this.handleClose = this.handleClose.bind(this)
        this.handleSave = this.handleSave.bind(this)
    }

    async handleSave() {
        let cadastro = {...this.state.cadastro}
        cadastro.userId = sessionStorage.getItem('userId')
        cadastro.serId = this.props.paciente._id

        if ( cadastro.nome.trim() === '' ) {
            this.setState( {
                showErroMessage: true,
                errorMedicacao: true
            })
            return
        }

        this.setState({
            saving: true, 
            showErroMessage:false,   
            fatalErroMessage:false
        })

        axios.post(Utils.getUrl()+"medicacoesRealizadas/",{...cadastro}).then(
            resp => {
                if ( resp.status === 201 || resp.status === 200) { 
                    this.setState( {saving: false})                     
                    this.handleClose(true)
                }
                else {
                    console.log('Erro ao cadastrar Medicacao')
                    this.setState({fatalErroMessage:true, saving: false})
                }
            }
        ).catch( e => {    
            console.log('Erro ao cadastrar Medicacao')
            console.log(e)  
            this.setState({fatalErroMessage:true, saving: false})
        })
    }

    handleClose(saving) {
        this.setState( {            
            cadastro: {
                nome: '',
                posologia: '',
                data: new Date(),
                fim: new Date(),
                observacoes: ''
            },
            loading: false,
            errorMedicacao: false,
            showErroMessage:false,   
            fatalErroMessage:false
        })

        if (saving) {
            this.props.onSave()
        }
        else this.props.onClose()
    }

    componentDidMount() {
        let userId = sessionStorage.getItem('userId')

        //Buscar lista de medicacoes
        axios.get(Utils.getUrl()+"medicacoes/user/"+userId).then(
            resp => {
                if ( resp.status === 201 || resp.status === 200) {
                    let lista = resp.data

                    let listaFinal = []
                    for (var i=0; i<lista.length; i++) {
                        let nome = lista[i].nome
                        if ( !listaFinal.includes(nome) ) {
                            listaFinal.push(nome)
                        }
                    }                    
                    this.setState({listaMedicacoes:listaFinal.sort()})

                    axios.get(Utils.getUrl()+"medicacoesRealizadas/user/"+userId).then(
                        resp => {
                            if ( resp.status === 201 || resp.status === 200) {
                                let lista = resp.data

                                for (var i=0; i<lista.length; i++) {
                                    let nome = lista[i].nome
                                    if ( !listaFinal.includes(nome) ) {
                                        listaFinal.push(nome)
                                    }
                                }
                                this.setState({listaMedicacoes:listaFinal.sort()})
                            }
                        }
                    )                    
                }
                else console.log("Erro ao buscar medicacoes")
            }
        ) 
    }

    render() {
        return (
            <Dialog fullScreen 
                open={this.props.open} 
                onClose={this.handleClose} 
                TransitionComponent={Transition}>

                <AppBar position="static">
                    <Toolbar>
                        <Typography component="h1" variant="h6" color="inherit" noWrap style={{flexGrow: 1}}>
                            Nova Medicação
                        </Typography>

                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => this.handleClose(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <Grid container spacing={3} style={{backgroundColor: '#FAFAFA'}} > 
                    
                    <Grid item xs={2} style={{backgroundColor: '#FFF', marginTop: '10px'}}>  
                        <Avatar src={this.props.paciente.avatar} 
                                style={{width: '30px', height: '30px', margin: '10px'}}/>  
                    </Grid>
                    <Grid item xs={10} style={{display: 'flex', flexDirection: 'column', 
                        justifyContent: 'center', backgroundColor: '#FFF', marginTop: '10px'}}>      
                        <Typography variant='subtitle2' noWrap>                                                    
                            {this.props.paciente.nome}
                        </Typography> 

                    </Grid>

                    <Grid item xs={12} style={{backgroundColor: '#FAFAFA', height: '100%', margin: '15px'}}>

                        <Grid container spacing={3}>

                            <Grid item xs={12}>                        
                                <Autocomplete
                                    id="medicacao"
                                    freeSolo
                                    disableClearable
                                    options={this.state.listaMedicacoes.map((option) => option)}
                                    value={this.state.cadastro.nome}
                                    onSelect={ () => {
                                        let s = document.getElementById('medicacao').value
                                        this.setState({errorMedicacao:false,
                                            cadastro: {...this.state.cadastro, 
                                                nome: s}})
                                    }}    
                                    renderInput={(params) => (
                                        <TextField {...params} 
                                            value={this.state.cadastro.nome}
                                            label="Medicação"  
                                            variant="outlined"
                                            error={this.state.errorMedicacao}  
                                            required                             
                                            onChange={ (e) => {
                                                this.setState({errorMedicacao:false, cadastro: {...this.state.cadastro, 
                                                    nome: e.target.value}} ) 
                                            }} 
                                        />
                                )}/>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField id="posologia" 
                                    fullWidth
                                    label="Posologia"  
                                    variant='outlined' 
                                    value={this.state.cadastro.posologia}
                                    onChange={ (e) => 
                                        this.setState( { cadastro: {...this.state.cadastro, 
                                            posologia: e.target.value} } ) }/>
                            </Grid>

                            <Grid item xs={12} sm={6}>                        
                                <MuiPickersUtilsProvider utils={LuxonUtils}>
                                    <KeyboardDateTimePicker
                                        id="data"
                                        fullWidth
                                        format="dd/MM/yyyy HH:mm"
                                        label="Início" 
                                        inputVariant='outlined'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}     
                                        value={this.state.cadastro.data}
                                        onChange={ (e) => 
                                            this.setState( { cadastro: {...this.state.cadastro, 
                                                data: new Date(e) }}) }
                                    />   
                                </MuiPickersUtilsProvider>                        
                            </Grid>

                            <Grid item xs={12} sm={6}>                        
                                <MuiPickersUtilsProvider utils={LuxonUtils}>
                                    <KeyboardDateTimePicker
                                        id="data"
                                        fullWidth
                                        format="dd/MM/yyyy HH:mm"
                                        label="Fim" 
                                        inputVariant='outlined'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}     
                                        value={this.state.cadastro.fim}
                                        onChange={ (e) => 
                                            this.setState( { cadastro: {...this.state.cadastro, 
                                                fim: new Date(e) }}) }
                                    />   
                                </MuiPickersUtilsProvider>                        
                            </Grid>

                            <Grid item xs={12}>
                                <TextField id="observacoesMedicacao" 
                                    fullWidth
                                    multiline
                                    rows={4}
                                    label="Observações"  
                                    variant='outlined' 
                                    value={this.state.cadastro.observacoes}
                                    onChange={ (e) => 
                                        this.setState( { cadastro: {...this.state.cadastro, 
                                            observacoes: e.target.value }} ) }/>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

                <AppBar position="fixed" style={{top: 'auto', bottom: 0, backgroundColor: 'white'}}>
                    <Toolbar style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <Button color="primary" variant='contained' onClick={this.handleSave}>
                            Salvar
                        </Button>
                    </Toolbar>
                </AppBar> 

                <Snackbar open={this.state.showErroMessage} autoHideDuration={6000} 
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => this.setState({showErroMessage:false})}>
                    <MuiAlert severity="error" elevation={6} variant="filled" onClose={ () => this.setState({showErroMessage:false})}>
                        Por favor, verifique os campos e tente novamente.
                    </MuiAlert>
                </Snackbar>

                <Snackbar open={this.state.fatalErroMessage} autoHideDuration={6000} 
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => this.setState({fatalErroMessage:false})}>
                    <MuiAlert severity="error" elevation={6} variant="filled" onClose={ () => this.setState({fatalErroMessage:false})}>
                        Erro ao realizar cadastro. Por favor, tente novamente mais tarde.
                    </MuiAlert>
                </Snackbar>
                
                <Backdrop
                    style={{ color: '#fff', zIndex: 10000 }}
                    open={this.state.saving || this.state.loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Dialog>
        )
    }
}