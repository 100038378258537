import React, { Component } from 'react';
import {Box, Button, Dialog, DialogTitle} from '@material-ui/core';
import {DialogActions, DialogContent} from '@material-ui/core/';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import '../css/Generic.css'

export class ConfirmDialog extends Component {

    render() {

        return (
            <Dialog 
                open={this.props.open}
                onClose={this.props.handleClose}>

                        <DialogTitle style={{backgroundColor: '#3F51B5'}}>  
                            <center>                     
                                <HelpOutlineIcon style={{ color: 'white' }} fontSize="large"/>
                            </center> 
                        </DialogTitle>

                        <DialogContent>
                            <Box m={5} />
                            {this.props.message}
                            <Box m={5} />
                        </DialogContent>
                        
                        <DialogActions>

                            <Button variant="contained" color="primary" onClick={this.props.handleOk}>
                                Ok
                            </Button>
                            <Button variant="outlined" color="default" onClick={this.props.handleClose}>
                                Cancelar
                            </Button>

                        </DialogActions>
            </Dialog>
        )
    }
}