import React, { Component } from 'react';
import { Grid, Typography, Slide, Dialog } from '@material-ui/core';
import {Toolbar,IconButton } from '@material-ui/core';
import { TextField, AppBar, Fab, Avatar, Snackbar, Backdrop, CircularProgress } from '@material-ui/core';
import { KeyboardArrowLeft } from '@material-ui/icons';
import MuiAlert from '@material-ui/lab/Alert';

import {Utils} from '../generic/Utils';
import axios from 'axios';
import '../css/Generic.css'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export class CarrosEditDialog extends Component {

    constructor(props) {
        super(props)

        this.state = {
            cadastro: {
                avatar: '../images/icons/cars/car.png',
                cor: '#F0EFEE',
                modelo: '',
                nome: '',
                ano: 2000,
                placa: '',
                observacoes: ''
            },
            showNomeMessage: false,
            saving: false
        }

        this.handleSave = this.handleSave.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    handleClose() {
        this.setState({            
            showNomeMessage: false,
            saving: false
        })

        this.props.onClose()
    }

    handleSave() {
        let cadastro = {...this.state.cadastro}

        if ( cadastro.nome.trim() === '') {
            this.setState({showNomeMessage: true})
            return
        }

        this.setState({saving: true, 
            showNomeMessage: false
        })
        
        cadastro._id = this.props.car._id
        cadastro.userId = sessionStorage.getItem('userId')

        axios.put(Utils.getUrl()+"carros/",{...cadastro}).then(
            resp => {
                if ( resp.status === 201 || resp.status === 200) {   

                    sessionStorage.setItem("carroNome",cadastro.nome)
                    sessionStorage.setItem("carroAvatar",cadastro.avatar)
                    sessionStorage.setItem("carroCor",cadastro.cor)

                    this.setState( {                        
                        showNomeMessage: false,
                        saving: false,
                        activeStep: 0
                    })  

                    this.props.onSave()
                }
                else {
                    this.setState({saving: false})
                }
            }
        ).catch( e => {      
            this.setState({saving: false})
        })
    }

    componentDidMount() {
        this.setState({
            cadastro: {
                avatar: this.props.car.avatar,
                cor: this.props.car.cor,
                modelo: this.props.car.modelo,
                nome: this.props.car.nome,
                ano: this.props.car.ano,
                placa: this.props.car.placa,
                observacoes: this.props.car.observacoes
            }
        })
    }

    render() {

        return (
            <Dialog fullScreen 
                open={this.props.open} 
                onClose={this.handleClose} 
                onRendered={this.onRendered}
                TransitionComponent={Transition}>

                    <AppBar position="static" style={{minHeight: '50px'}}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={this.handleClose}>
                                <KeyboardArrowLeft />
                            </IconButton>

                            <Typography component="h1" variant="h6" color="inherit" noWrap>
                               {this.props.car.nome}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                            
                    <Grid container spacing={3} style={{padding: '15px'}}> 


                        <Grid item xs={12} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <Avatar src={this.state.cadastro.avatar} 
                                style={{width: '100px', height: '100px', 
                                    margin: '10px', marginTop: '30px', backgroundColor: this.state.cadastro.cor}}/>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField id="nome" 
                                required
                                fullWidth
                                error={this.state.showNomeMessage}
                                label="Apelido"  
                                variant='outlined' 
                                value={this.state.cadastro.nome}
                                onChange={ (e) => 
                                    this.setState( { cadastro: {...this.state.cadastro, 
                                    nome: e.target.value} } ) }/>
                        </Grid> 

                        <Grid item xs={12}>
                            <TextField id="modelo" 
                                fullWidth
                                label="Modelo"  
                                variant='outlined' 
                                value={this.state.cadastro.modelo}
                                onChange={ (e) => 
                                    this.setState( { cadastro: {...this.state.cadastro, 
                                    modelo: e.target.value} } ) }/>
                        </Grid> 

                        <Grid item xs={12}>
                            <TextField id="placa" 
                                fullWidth
                                label="Placa"  
                                variant='outlined' 
                                value={this.state.cadastro.placa}
                                onChange={ (e) => 
                                    this.setState( { cadastro: {...this.state.cadastro, 
                                        placa: e.target.value} } ) }/>
                        </Grid> 

                        <Grid item xs={12}>
                            <TextField id="ano" 
                                fullWidth
                                type="number"
                                label="Ano Fabricação"  
                                variant='outlined' 
                                value={this.state.cadastro.ano}                                                               
                                onChange={ (e) => {
                                        this.setState( { cadastro: {...this.state.cadastro, 
                                            ano: e.target.value} } ) 
                                } }/>
                        </Grid> 

                            <Grid item xs={12}>
                            <TextField id="observacoes" 
                                fullWidth
                                multiline
                                rows={4}
                                label="Observações?"  
                                variant='outlined' 
                                value={this.state.cadastro.observacoes}
                                onChange={ (e) => 
                                    this.setState( { cadastro: {...this.state.cadastro, 
                                    observacoes: e.target.value} } ) }/>
                        </Grid>
                        <Grid item xs={12}>  
                            <div style={{textAlign:'center'}}>                        
                                <Fab variant="extended" color="primary" style={{width: '90%'}} onClick={this.handleSave}>
                                    Finalizar
                                </Fab> 
                            </div>   
                        </Grid> 

                    </Grid>
                        

                    <Snackbar open={this.state.showNomeMessage} autoHideDuration={6000} 
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => this.setState({showNomeMessage:false})}>
                        <MuiAlert severity="error" elevation={6} variant="filled" onClose={ () => this.setState({showNomeMessage:false})}>
                            Por favor, verifique os campos e tente novamente.
                        </MuiAlert>
                    </Snackbar>

                    <Backdrop
                        style={{ color: '#fff', zIndex: 10000 }}
                        open={this.state.saving}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
            </Dialog>
        )
    }

}