import React, { Component } from 'react';
import { Grid, Typography, Drawer, Chip, IconButton } from '@material-ui/core';
import { List, ListItem, ListItemText, CardActionArea } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';

import {ConfirmDialog} from '../util/ConfirmDialog'
import { DateTime } from "luxon";

import {Utils} from '../generic/Utils';
import axios from 'axios';
import '../css/Generic.css'

export class ConsultaDetalhesDrawer extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            confirmDelete: false,
            selected: {}
        }  
        this.handleDelete = this.handleDelete.bind(this)
    }

    handleDelete() {        
        let entity = this.state.selected
        
        this.setState({loading: true})
        axios.delete(Utils.getUrl()+"consultas/"+entity._id,).then(
            resp => {
                this.setState({loading: false, confirmDelete: false})
                this.props.onDelete()
            }
        ).catch( e => {
            console.log("Erro ao deletar "+e) 
            this.setState({loading: false, confirmDelete: false})
        })
    }

    render() {
        return (
        <Drawer anchor="bottom" open={this.props.open} onClose={() => this.props.onClose()}>
            
            {this.props.open?(
                <Grid container spacing={3} style={{padding: '10px', paddingTop: '20px'}}>  
                    <Grid item xs={10}>     
                        <Typography variant="h6">
                            {this.props.consulta.medico.nome}
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                            {this.props.consulta.medico.especialidade} &nbsp;-&nbsp;
                            {DateTime.fromISO(this.props.consulta.data).setLocale('pt').toLocaleString()}
                        </Typography> 
                    </Grid>  
                    <Grid item xs={2} style={{display: 'flex', justifyContent: 'center'}}>                             
                        <IconButton onClick={ () => this.setState({confirmDelete: true, selected: this.props.consulta}) }>
                            <DeleteIcon/>   
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            Sintomas:                  
                        </Typography>
                        <Typography variant="subtitle" color="textSecondary">
                            {this.props.consulta.sintomas.length>0?(
                                this.props.consulta.sintomas.map( (s) => 
                                    <Chip label={s.descricao} color="secondary" variant="outlined" style={{marginRight: '5px'}}/> )) 
                                : "Não Informado"}                   
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            Medicações:                  
                        </Typography>
                        <Typography variant="subtitle" color="textSecondary">
                            <List className="fullSizeList">       
                                {this.props.consulta.medicacoes.length>0?(
                                    this.props.consulta.medicacoes.map( (e) =>   
                                        <CardActionArea>
                                            <ListItem key={e.nome}>
                                                <ListItemText 
                                                    primary={e.nome + 
                                                        " ("+DateTime.fromISO(e.inicio).setLocale('pt').toLocaleString()+
                                                        " - "+
                                                        DateTime.fromISO(e.fim).setLocale('pt').toLocaleString() + ")"}
                                                    secondary={e.posologia} />
                                            </ListItem>
                                        </CardActionArea>                                                
                                    ) )
                                : "Não Informado"} 
                            </List>                   
                        </Typography>
                    </Grid>
                </Grid>
            ):""}

            <ConfirmDialog open={this.state.confirmDelete}
                handleClose={ () => this.setState({confirmDelete: false}) }
                handleOk={this.handleDelete}
                message="Você deseja realmente excluir? Essa operação não pode ser desfeita!"
            />

        </Drawer>
        )
    }
}