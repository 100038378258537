
const moneyFormat = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  });

  const avatarCarImages = [
    "../images/icons/cars/car.png",
    "../images/icons/cars/moto.png",
    "../images/icons/cars/bike.png",
  ]

  const avatarPetImages = [
    "../images/icons/pets/dog0.png",
    "../images/icons/pets/dog9.png",
    "../images/icons/pets/dog5.png",
    "../images/icons/pets/dog1.png",
    "../images/icons/pets/dog6.png",
    "../images/icons/pets/dog3.png",
    "../images/icons/pets/dog7.png",
    "../images/icons/pets/dog2.png",
    "../images/icons/pets/dog4.png",
    "../images/icons/pets/cat1.png",
    "../images/icons/pets/rabbit1.png",
  ]


  const avatarImages = [
    "../images/icons/boy1Blue.png",
    "../images/icons/boy1Green.png",
    "../images/icons/boy1Pink.png",
    "../images/icons/boy1Yellow.png",
    "../images/icons/boy2Blue.png",
    "../images/icons/boy2Green.png",
    "../images/icons/boy2Pink.png",
    "../images/icons/boy2Yellow.png",
    "../images/icons/girlBlue.png",
    "../images/icons/girlGreen.png",
    "../images/icons/girlPink.png",
    "../images/icons/girlYellow.png",
    "../images/icons/girl2Blue.png",
    "../images/icons/girl2Green.png",
    "../images/icons/girl2Pink.png",
    "../images/icons/girl2Yellow.png"];

export class Utils {

    static getAppName() {
        return "My Timeline"
    }

    static getAvatares() {
        return avatarImages
    }

    static getPetAvatares() {
        return avatarPetImages
    }

    static getCarAvatares() {
        return avatarCarImages
    }

    static getCarColors() {
        return ["#F0EFEE", "#3A3A3A","#959392","#FCD24A","#DF5224", "#0C695D",
        "#4F090A","#BB2516","#79BA2B", "pink", "#73EFEF", "#115293"]
    }

    static getUrl() {
        return 'https://api-dot-pgo-diario.rj.r.appspot.com/' 
        //return 'http://localhost:8080/'
    }

    static parseData(valor) {
        var valores = valor.split('/')
        var ano = valores[2]
        var mes = valores[1]
        var dia = valores[0]
    
        return new Date(ano+'-'+mes+'-'+dia+'T00:00:00')
    }

    static formatarCPF(cpf){
        //retira os caracteres indesejados...
        cpf = cpf.replace(/[^\d]/g, "");
      
        //realizar a formatação...
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }

    static formatarData(d) {
        let data = new Date(d)

        let dia = data.getDate()
        if ( dia<10 ) dia = '0'+dia

        let mes = (data.getMonth()+1)
        if ( mes<10 ) mes = '0'+mes


        let datestring =  dia  + "/" + mes + "/" +  data.getFullYear() 
        return datestring
    }

    static formatarDataHora(d) {
        let data = new Date(d)

        let dia = data.getDate()
        if ( dia<10 ) dia = '0'+dia

        let mes = (data.getMonth()+1)
        if ( mes<10 ) mes = '0'+mes

        let hora = data.getHours()
        let min = data.getMinutes()

        if (min < 10) min = '0'+min

        let datestring =  dia  + "/" + mes + "/" +  data.getFullYear() +' '+ hora + ':' +min
        return datestring
    }

    static formatarDinheiro(valor,showMoney) {
        if (showMoney==='false') return ''
        else return moneyFormat.format(valor)
    }

    static formatarPercentual(valor) {
        let result = 0
        if ( valor != null) result = valor
        return result.toFixed(2)+'%'
    }
}