import React, { Component } from 'react';
import { Grid, MobileStepper, Button, Typography, Slide, Dialog } from '@material-ui/core';
import {Toolbar,IconButton } from '@material-ui/core';
import { TextField, AppBar, Fab, Avatar, Snackbar, Backdrop, CircularProgress } from '@material-ui/core';
import {KeyboardArrowRight, KeyboardArrowLeft } from '@material-ui/icons';
import MuiAlert from '@material-ui/lab/Alert';

import {Utils} from '../generic/Utils';
import axios from 'axios';
import '../css/Generic.css'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export class CarrosFormDialog extends Component {

    constructor(props) {
        super(props)

        this.state = {
            cadastro: {
                avatar: '../images/icons/cars/car.png',
                cor: '#F0EFEE',
                modelo: '',
                nome: '',
                ano: 2000,
                placa: '',
                observacoes: ''
            },
            showNomeMessage: false,
            saving: false,
            activeStep: 0
        }

        this.handleSave = this.handleSave.bind(this)
        this.handleSex = this.handleSex.bind(this)
        this.handleNome = this.handleNome.bind(this)
        this.handleAvatar = this.handleAvatar.bind(this)
        this.handleCor = this.handleCor.bind(this)
        this.handleNext = this.handleNext.bind(this)
        this.handleBack = this.handleBack.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    handleClose() {
        this.setState({
            cadastro: {
                avatar: '../images/icons/cars/car.png',
                cor: '#F0EFEE',
                modelo: '',
                nome: '',
                ano: 2000,
                placa: '',
                observacoes: ''
            },
            showNomeMessage: false,
            saving: false,
            activeStep: 0
        })

        this.props.onClose()
    }

    handleSave() {
        let cadastro = {...this.state.cadastro}
        this.setState({saving: true, 
            showNomeMessage: false
        })

        cadastro.userId = sessionStorage.getItem('userId')


        axios.post(Utils.getUrl()+"carros/",{...cadastro}).then(
            resp => {
                if ( resp.status === 201 || resp.status === 200) {   

                    sessionStorage.setItem("carroNome",cadastro.nome)
                    sessionStorage.setItem("carroAvatar",cadastro.avatar)
                    sessionStorage.setItem("carroCor",cadastro.cor)

                    this.setState( {
                        cadastro: {
                            avatar: '../images/icons/cars/car.png',
                            cor: '#F0EFEE',
                            modelo: '',
                            nome: '',
                            ano: 2000,
                            placa: '',
                            observacoes: ''
                        },
                        showNomeMessage: false,
                        saving: false,
                        activeStep: 0
                    })  

                    this.props.onSave()
                }
                else {
                    this.setState({saving: false})
                }
            }
        ).catch( e => {      
            this.setState({saving: false})
        })
    }

    handleAvatar(item) {
        this.setState({cadastro: {...this.state.cadastro, avatar: item}})
    }

    handleCor(item) {
        this.setState({cadastro: {...this.state.cadastro, cor: item}})
    }

    handleSex(s) {
        this.setState({cadastro: {...this.state.cadastro, sexo: s}})
        this.handleNext()
    }

    handleNome() {
        let step = this.state.activeStep

        if ( !this.state.cadastro.nome ) this.setState({showNomeMessage: true})
        else {
            this.setState({activeStep: step+1})
        }
    }

    handleNext() {
        let step = this.state.activeStep
        this.setState({activeStep: step+1})
    }

    handleBack() {
        let step = this.state.activeStep
        if ( step-1 === -1 ) this.handleClose()
        else this.setState({activeStep: step-1})
    }

    getAvatarList() {
        return Utils.getCarAvatares()
    }

    getStepContent() {    

        switch (this.state.activeStep) {

            case 0:
                return (  
                    <Grid container spacing={3}>  
                        <Grid item xs={12}>
                            <Typography variant='h6'>
                                Escolha uma figurinha e uma cor...
                            </Typography>
                        </Grid>    
                        <Grid item xs={12} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}> 
                            <Avatar src={this.state.cadastro.avatar}
                                style={{width: '100px', 
                                    height: '100px', 
                                    backgroundColor: this.state.cadastro.cor,
                                    textAlign:'center'}}/>
                        </Grid>                     
                        <Grid item xs={12}>
                            <Grid container spacing={3} alignItems="center"> 
                                {this.getAvatarList().map((item) => (
                                    <Grid item xs={4} sm={2} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                        <Avatar src={item} 
                                            onClick={() => this.handleAvatar(item)} 
                                            style={{width: '100px', height: '100px', textAlign:'center', cursor: 'pointer'}}/>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>                        
                        <Grid item xs={12}>
                            <Grid container spacing={3} alignItems="center"> 
                                {Utils.getCarColors().map((item) => (
                                    <Grid item xs={2}  style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                        <Avatar 
                                            src="../images/none.png"
                                            onClick={() => this.handleCor(item)} 
                                            style={{width: '50px', height: '50px', textAlign:'center', cursor: 'pointer', backgroundColor: item}}/>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>                                                     
                    </Grid>   
                );
            case 1:
                return (
                    <Grid container spacing={3}>  
                        <Grid item xs={12}>
                            <Typography variant='h6'>
                                Conte um pouco sobre ele...
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField id="nome" 
                                required
                                fullWidth
                                error={this.state.showNomeMessage}
                                label="Apelido"  
                                variant='outlined' 
                                value={this.state.cadastro.nome}
                                onChange={ (e) => 
                                    this.setState( { cadastro: {...this.state.cadastro, 
                                    nome: e.target.value} } ) }/>
                        </Grid> 

                        <Grid item xs={12}>
                            <TextField id="modelo" 
                                fullWidth
                                label="Modelo"  
                                variant='outlined' 
                                value={this.state.cadastro.modelo}
                                onChange={ (e) => 
                                    this.setState( { cadastro: {...this.state.cadastro, 
                                    modelo: e.target.value} } ) }/>
                        </Grid> 

                        <Grid item xs={12}>
                            <TextField id="placa" 
                                fullWidth
                                label="Placa"  
                                variant='outlined' 
                                value={this.state.cadastro.placa}
                                onChange={ (e) => 
                                    this.setState( { cadastro: {...this.state.cadastro, 
                                        placa: e.target.value} } ) }/>
                        </Grid> 

                        <Grid item xs={12}>
                            <TextField id="ano" 
                                fullWidth
                                type="number"
                                label="Ano Fabricação"  
                                variant='outlined' 
                                value={this.state.cadastro.ano}                                                               
                                onChange={ (e) => {
                                        this.setState( { cadastro: {...this.state.cadastro, 
                                            ano: e.target.value} } ) 
                                } }/>
                        </Grid> 

                        <Grid item xs={12}>  
                            <div style={{textAlign:'center'}}>                        
                                <Fab variant="extended" color="primary" style={{width: '90%'}} onClick={this.handleNome}>
                                    Próximo
                                </Fab> 
                            </div>   
                        </Grid>   
                    </Grid>
                );
            case 2:
                return (
                    <Grid container spacing={3}>  
                        <Grid item xs={12}>
                            <Typography variant='h6'>
                                Alguma observação adicional?
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField id="observacoes" 
                                fullWidth
                                multiline
                                rows={4}
                                label="Observações?"  
                                variant='outlined' 
                                value={this.state.cadastro.observacoes}
                                onChange={ (e) => 
                                    this.setState( { cadastro: {...this.state.cadastro, 
                                    observacoes: e.target.value} } ) }/>
                        </Grid>
                        <Grid item xs={12}>  
                            <div style={{textAlign:'center'}}>                        
                                <Fab variant="extended" color="primary" style={{width: '90%'}} onClick={this.handleSave}>
                                    Finalizar
                                </Fab> 
                            </div>   
                        </Grid> 
                    </Grid>
                );
            
            default: return;
        
        }
    }

    render() {

        return (
            <Dialog fullScreen 
                open={this.props.open} 
                onClose={this.handleClose} 
                onRendered={this.onRendered}
                TransitionComponent={Transition}>
                    <AppBar position="static" style={{minHeight: '50px'}}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={this.handleClose}>
                                <KeyboardArrowLeft />
                            </IconButton>

                            <Typography component="h1" variant="h6" color="inherit" noWrap>
                               Novo Veículo
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <Grid container spacing={3} alignItems="center">
                        
                        <Grid item xs={12}>
                            <MobileStepper
                                variant="dots"
                                steps={3}
                                position="static"
                                activeStep={this.state.activeStep}
                                nextButton={
                                    <Button size="small" onClick={this.handleNext} disabled={[1,2].includes(this.state.activeStep)}>
                                        <KeyboardArrowRight />
                                    </Button>
                                }
                                backButton={
                                    <Button size="small" onClick={this.handleBack} disabled={this.state.activeStep===0}>
                                        <KeyboardArrowLeft />
                                    </Button>
                                }
                            />
                        </Grid>

                        <Grid item xs={12} style={{marginRight: '15px', marginLeft: '15px', marginBottom: '15px'}}>
                            {this.getStepContent()}
                        </Grid>

                    </Grid>

                    <Snackbar open={this.state.showNomeMessage} autoHideDuration={6000} 
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => this.setState({showNomeMessage:false})}>
                        <MuiAlert severity="error" elevation={6} variant="filled" onClose={ () => this.setState({showNomeMessage:false})}>
                            Por favor, verifique os campos e tente novamente.
                        </MuiAlert>
                    </Snackbar>

                    <Backdrop
                        style={{ color: '#fff', zIndex: 10000 }}
                        open={this.state.saving}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
            </Dialog>
        )
    }

}