import React, { Component } from 'react';
import { Grid, Drawer, Avatar, Typography, CardActionArea } from '@material-ui/core';
import '../css/Generic.css'
import { EventosFormDialog } from '../carros/EventosFormDialog';

export class SelecaoCarroDrawer extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            car: {},
            openEventosViewDialog: false,
            tipoEvento: 'revisao',
            loading: true
        }  
        this.handleSelect = this.handleSelect.bind(this)
    }

    handleSelect() {
        this.setState({openEventosViewDialog: true, tipoEvento: this.props.tipo})
    }

    render() {
        return (
        <Drawer anchor="bottom" open={this.props.open} onClose={ () => this.props.onClose() }>
            
            {this.props.open?(
                <Grid container spacing={3} style={{padding: '10px', paddingTop: '20px'}}>  
                    <Grid item xs={12}>
                        <Typography variant="h6">Selecione o veículo...</Typography>
                    </Grid>

                    <Grid item xs={12}>
                    {
                        this.props.lista.map( (e) => 
                            <CardActionArea onClick={ () => {this.setState({car: e}, this.handleSelect)}} style={{padding: '5px'}}>
                                <Grid container spacing={3} style={{display: 'flex', alignItems: 'center'}}>
                                    <Grid item xs={2}>
                                        <Avatar src={e.avatar} style={{backgroundColor: e.cor}}/>
                                    </Grid>
                                    <Grid item xs={10}>
                                        {e.nome}
                                    </Grid>
                                </Grid>
                            </CardActionArea>
                        )
                    }
                    </Grid> 

                    <EventosFormDialog
                        tipo={this.state.tipoEvento}
                        open={this.state.openEventosViewDialog}
                        onClose={()=>this.setState({openEventosViewDialog:false},this.props.onClose)} 
                        onSave={()=>this.setState({openEventosViewDialog:false},this.props.onClose)}
                        car={this.state.car}/>

                </Grid>
            ):""}


            

        </Drawer>
        )
    }
}