import React, { Component } from 'react';
import { Grid, Typography, Drawer, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';

import {ConfirmDialog} from '../util/ConfirmDialog'
import { DateTime } from "luxon";

import {Utils} from '../generic/Utils';
import axios from 'axios';
import '../css/Generic.css'

export class VacinasDetalhesDrawer extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            confirmDelete: false
        }  
        this.handleDelete = this.handleDelete.bind(this)
    }

    handleDelete() {        
        let entity = this.props.vacina
        
        this.setState({loading: true})
        axios.delete(Utils.getUrl()+"vacinas/"+entity._id,).then(
            resp => {
                this.setState({loading: false, confirmDelete: false})
                this.props.onDelete()
            }
        ).catch( e => {
            console.log("Erro ao deletar "+e) 
            this.setState({loading: false, confirmDelete: false})
        })
    }

    render() {
        return (
        <Drawer anchor="bottom" open={this.props.open} onClose={() => this.props.onClose()}>
            
            {this.props.open?(
                <Grid container spacing={3} style={{padding: '10px', paddingTop: '20px'}}>  
                    <Grid item xs={10}>     
                        <Typography variant="h6">
                            {this.props.vacina.nome}
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                            {DateTime.fromISO(this.props.vacina.data).setLocale('pt').toFormat("dd/MM/yyyy")}
                        </Typography> 
                    </Grid>  

                    <Grid item xs={2} style={{display: 'flex', justifyContent: 'center'}}>                             
                        <IconButton onClick={ () => this.setState({confirmDelete: true, selected: this.props.vacina}) }>
                            <DeleteIcon/>   
                        </IconButton>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="body1">
                            Observações:                  
                        </Typography>
                        <Typography variant="subtitle" color="textSecondary">                            
                            {this.props.vacina.observacoes?this.props.vacina.observacoes:"Nada Informado"}                                    
                        </Typography>
                    </Grid>
                </Grid>
            ):""}

            <ConfirmDialog open={this.state.confirmDelete}
                handleClose={ () => this.setState({confirmDelete: false}) }
                handleOk={this.handleDelete}
                message="Você deseja realmente excluir? Essa operação não pode ser desfeita!"
            />

        </Drawer>
        )
    }
}