import React, { Component } from 'react';
import { Card, CardMedia, CardContent, CardActionArea, Typography } from '@material-ui/core';
import { CarrosViewDialog } from '../carros/CarrosViewDialog';

export default class CarSwiperCard extends Component {

    constructor(props) {
        super(props)

        this.state = {
            openViewDialog: false
        }  
    }
        
  render() {
      return (      
        <Card style={{maxWidth: '150px'}} square={false} elevation={1} variant="elevation">
            <CardActionArea onClick={() => this.setState({openViewDialog:true})}>  
                <CardMedia component="img" src={this.props.car.avatar} style={{backgroundColor: this.props.car.cor}}/>
                <CardContent>
                    <Typography variant='body1'>
                        <b>{this.props.car.nome.split(" ")[0]}</b>
                    </Typography>
                    <Typography variant='caption'>
                        {this.props.car.ano}
                    </Typography>
                </CardContent>
            </CardActionArea>
            
            
            <CarrosViewDialog
                open={this.state.openViewDialog} 
                onClose={()=>this.setState({openViewDialog: false})}
                onRefresh={this.props.onRefresh}
                car={this.props.car}/>
           
        </Card>  
      )
    }        

}