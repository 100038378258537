import React, { Component } from 'react';
import { Grid, Typography, Slide, Dialog, Paper, Box, Button, Snackbar } from '@material-ui/core';
import { Toolbar, AppBar, IconButton, Avatar, Backdrop, CircularProgress } from '@material-ui/core';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { KeyboardArrowLeft } from '@material-ui/icons';
import MuiAlert from '@material-ui/lab/Alert';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { DateTime } from "luxon";

import {Utils} from '../generic/Utils';
import axios from 'axios';
import '../css/Generic.css'
import { ConsultaDetalhesDrawer } from './ConsultaDetalhesDrawer';
import { ConsultaFormDialog } from './ConsultaFormDialog';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const baseUrl = Utils.getUrl()+"consultas/";

export class ConsultasViewDialog extends Component {


    constructor(props) {
        super(props)

        this.state = {  
            filtro: {
                periodo: 1,
                medicacao: 1,
                especialidade: 1,
                sintoma: 1
            },
            lista: [],
            openDetalhe: false,
            openForm: false,
            consultaSelecionada: {}
        }

        this.onRendered = this.onRendered.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    buscarLista() {

        let pacienteId = this.props.pessoa._id  
        let medicacao = this.state.filtro.medicacao        
        let especialidade = this.state.filtro.especialidade        
        let sintoma = this.state.filtro.sintoma
        this.setState({loading: true})

        axios.get(baseUrl+"paciente/"+pacienteId).then(
            resp => {
                if ( resp.status === 201 || resp.status === 200) {
                    let lista = resp.data

                    let listaEspecialidade = []
                    let listaSintomas = []
                    let listaMedicacao = []
                    let listaFinal = []

                    let filtered = true
                    for(var i=0; i<lista.length; i++) {
                        if ( !listaEspecialidade.includes(lista[i].medico.especialidade) ) {
                            listaEspecialidade.push(lista[i].medico.especialidade)
                        }

                        if ( lista[i].medicacoes ) {
                            for(var j=0; j<lista[i].medicacoes.length; j++) {
                                let s = lista[i].medicacoes[j].nome
                                if ( !listaMedicacao.includes(s) ) listaMedicacao.push(s)
                            }
                        }

                        if ( lista[i].sintomas ) {
                            for(j=0; j<lista[i].sintomas.length; j++) {
                                let s = lista[i].sintomas[j].descricao
                                if ( !listaSintomas.includes(s) ) listaSintomas.push(s)
                            }
                        }

                        if ( sintoma!==1 ) {
                            if ( !lista[i].sintomas || lista[i].sintomas.length===0) filtered = false
                            else {
                                let achou = false
                                for(j=0; j<lista[i].sintomas.length; j++) {
                                    let s = lista[i].sintomas[j].descricao
                                    if ( s === sintoma ) achou=true
                                }
                                if (!achou) filtered = false
                            }
                        }

                        if ( medicacao!==1 ) {
                            if ( !lista[i].medicacoes || lista[i].medicacoes.length===0) filtered = false
                            else {
                                let achou = false
                                for(j=0; j<lista[i].medicacoes.length; j++) {
                                    let m = lista[i].medicacoes[j].nome
                                    if ( m === medicacao ) achou=true
                                }
                                if (!achou) filtered = false
                            }
                        }

                        if ( especialidade !== 1 && lista[i].medico.especialidade !== especialidade ) filtered = false
                        

                        if (filtered) listaFinal.push(lista[i])
                        filtered = true
                    }

                    this.setState({lista:listaFinal, 
                        listaEspecialidade: listaEspecialidade.sort(),
                        listaSintomas: listaSintomas.sort(),
                        listaMedicacao: listaMedicacao.sort(),
                        loading: false})                        
                }
                else {
                    console.log("Erro ao buscar consultas")                    
                    this.setState({loading: true})
                }

            }
        ).catch( e => {
            console.log("Erro ao buscar consultas"+e) 
            this.setState({loading: false})
        })
    }

    handleClose() {
        this.setState({openDetalhe:false})
        this.props.onClose()
    }

    onRendered() {
        this.buscarLista()
    }

    handleSave() {
    }

    render() {

        return (
            <Dialog fullScreen 
                open={this.props.open} 
                onClose={this.handleClose} 
                onRendered={this.onRendered}
                TransitionComponent={Transition}>

                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={this.handleClose}>
                            <KeyboardArrowLeft />
                        </IconButton>

                        <Typography component="h1" variant="h6" color="inherit" noWrap style={{flexGrow: 1}}>
                            {this.props.pessoa.nome}
                        </Typography>
                    </Toolbar>
                </AppBar>
                
                <Grid container spacing={3} style={{backgroundColor: '#FAFAFA'}} >  

                    <Grid item xs={12} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#FFF'}}>  

                        <Avatar src="../images/icons/medic.png" 
                            style={{width: '60px', height: '60px', margin: '10px', marginTop: '30px',backgroundColor: "#F6F09D"}}/>
                            
                        <Typography variant='h6'>                                                        
                            Consultas
                        </Typography> 

                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={2} style={{padding: '10px', display: 'flex', alignContent: 'center'}}>
                            <Grid item xs={4}>
                                <InputLabel shrink id="especialidade-label">
                                    Especialidades
                                </InputLabel>
                                <Select
                                    labelId="especialidade-label"
                                    style={{maxHeight: '30px'}}
                                    fullWidth
                                    variant='outlined'
                                    value={this.state.filtro.especialidade}
                                    onChange={ (e) => {
                                            this.setState({filtro: {...this.state.filtro,
                                                especialidade: e.target.value}}, this.buscarLista)  
                                        }
                                    }>
                                    <MenuItem value={1}>
                                        Todas
                                    </MenuItem>
                                    {
                                        this.state.listaEspecialidade?this.state.listaEspecialidade.map( (e) => 
                                            <MenuItem value={e}>{e}</MenuItem>
                                        ):""
                                    }
                                </Select>
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabel shrink id="sintoma-label">
                                    Sintomas
                                </InputLabel>
                                <Select
                                    labelId="sintoma-label"
                                    style={{maxHeight: '30px'}}
                                    fullWidth
                                    variant='outlined'
                                    value={this.state.filtro.sintoma}
                                    onChange={ (e) => { 
                                            this.setState({filtro: {...this.state.filtro,
                                                sintoma: e.target.value}}, this.buscarLista)
                                        }
                                    }>
                                    <MenuItem value={1}>
                                        Todos
                                    </MenuItem>
                                    {
                                        this.state.listaSintomas?this.state.listaSintomas.map( (e) => 
                                            <MenuItem value={e}>{e}</MenuItem>
                                        ):""
                                    }
                                </Select>
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabel shrink id="medicacao-label">
                                    Medicações
                                </InputLabel>
                                <Select
                                    labelId="medicacao-label"
                                    style={{maxHeight: '30px'}}
                                    fullWidth
                                    variant='outlined'
                                    id="periodo-filter"
                                    value={this.state.filtro.medicacao}
                                    onChange={ (e) => {
                                        this.setState({filtro: {...this.state.filtro, medicacao: e.target.value}}, this.buscarLista) }                                    
                                    }>
                                    <MenuItem value={1}>
                                        Todas
                                    </MenuItem>                                    
                                    {
                                        this.state.listaMedicacao?this.state.listaMedicacao.map( (e) => 
                                            <MenuItem value={e}>{e}</MenuItem>
                                        ):""
                                    }
                                </Select>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    <Grid item xs={12} style={{backgroundColor: '#FAFAFA', height: '100%'}}  alignItems="center">

                    { 
                        this.state.lista.length > 0 ? (  
                            <Timeline align="alternate">

                                { this.state.lista.map( (e) => 
                                    <TimelineItem>
                                        <TimelineOppositeContent>
                                            <Typography variant="body2" color="textSecondary">
                                                {DateTime.fromISO(e.data).setLocale('pt').toLocaleString()}
                                            </Typography>
                                        </TimelineOppositeContent>

                                        <TimelineSeparator>
                                            <TimelineDot color="primary" />
                                            <TimelineConnector />
                                        </TimelineSeparator>

                                        <TimelineContent>
                                            <Paper elevation={3} style={{padding: '10px', textAlign: 'left', cursor: 'pointer'}} 
                                                onClick={() => this.setState({consultaSelecionada: e, openDetalhe: true})}>
                                                <Typography variant="body1">
                                                    <b>{e.medico.nome}</b>
                                                </Typography>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    {e.medico.especialidade}
                                                </Typography>  
                                            </Paper>
                                        </TimelineContent>
                                    </TimelineItem>
                                )}
                            </Timeline>
                        ) : 
                        (
                            <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center'}}>
                                <Avatar src="../images/icons/empty.png" style={{width: '150px', height: '150px', textAlign: 'center', marginTop: '50px'}}/>
                                <Typography variant="subtitle">
                                    Nada encontrado por aqui!
                                </Typography>
                            </Box>
                        )
                    }

                    </Grid>
                </Grid>

                <AppBar position="fixed" style={{top: 'auto', bottom: 0, backgroundColor: 'white'}}>
                    <Toolbar style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <Button color="primary" variant='contained' onClick={()=>this.setState({openForm: true})}>
                            Nova
                        </Button>
                    </Toolbar>
                </AppBar>
                
                <ConsultaDetalhesDrawer
                    open={this.state.openDetalhe}
                    onClose={()=>this.setState({openDetalhe:false})}
                    onDelete={()=>{
                        this.setState({deleteMessage: true, openDetalhe:false})
                        this.buscarLista()
                    }}
                    consulta={this.state.consultaSelecionada} />

                <ConsultaFormDialog
                    open={this.state.openForm}
                    onClose={()=>this.setState({openForm:false})} 
                    onSave={()=>{
                        this.setState({successMessage: true, openForm:false})
                        this.buscarLista()
                    }}
                    paciente={this.props.pessoa}/>

                <Snackbar open={this.state.successMessage} autoHideDuration={6000} 
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => this.setState({successMessage:false})}>
                    <MuiAlert severity="success" elevation={6} variant="filled" onClose={ () => this.setState({successMessage:false})}>
                        Consulta cadastrada com sucesso!
                    </MuiAlert>
                </Snackbar>

                <Snackbar open={this.state.deleteMessage} autoHideDuration={6000} 
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => this.setState({deleteMessage:false})}>
                    <MuiAlert severity="success" elevation={6} variant="filled" onClose={ () => this.setState({deleteMessage:false})}>
                        Consulta excluída com sucesso!
                    </MuiAlert>
                </Snackbar>

                <Backdrop
                    style={{ color: '#fff', zIndex: 10000 }}
                    open={this.state.saving}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Backdrop
                    style={{ color: '#fff', zIndex: 10000 }}
                    open={this.state.loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Dialog>
        )
    }

}