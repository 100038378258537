import React, { Component } from 'react';
import { Grid, Typography, Drawer, Avatar, Backdrop, CircularProgress } from '@material-ui/core';

import {Utils} from '../generic/Utils';
import axios from 'axios';
import '../css/Generic.css'

export class SeresAvatarDrawner extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            saving: false
        }  
    }

    
    handleAvatar(item) {
        this.setState({loading: true})

        let cadastro = {...this.props.pessoa}

        cadastro.avatar = item

        axios.put(Utils.getUrl()+"seres/",{...cadastro}).then(
            resp => {
                if ( resp.status === 201 || resp.status === 200) {  
                    console.log("Salvo avatar com sucesso") 
                    this.props.onSave()
                }
                else {
                    this.setState({saving: false})
                }
            }
        ).catch( e => {      
            this.setState({saving: false})
        })
    }

    getAvatarList() {
        if ( this.props.pessoa.tipo === 'pessoa')
            return Utils.getAvatares()
        else
            return Utils.getPetAvatares()
    }

    render() {
        return (
        <Drawer anchor="bottom" open={this.props.open} onClose={() => this.props.onClose()}>
            
            {this.props.open?(
                <Grid container spacing={3} style={{padding: '10px', paddingTop: '20px'}}>  
                    <Grid item xs={12}>
                        <Typography variant='h6'>
                            Escolha uma figurinha...
                        </Typography>
                    </Grid>                         
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems="center"> 
                            {this.getAvatarList().map((item) => (
                                <Grid item xs={4} sm={2} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <Avatar src={item} 
                                        onClick={() => this.handleAvatar(item)} 
                                        style={{width: '100px', height: '100px', textAlign:'center', cursor: 'pointer'}}/>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>     
                </Grid>
            ):""}

            <Backdrop
                style={{ color: '#fff', zIndex: 10000 }}
                open={this.state.saving}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </Drawer>
        )
    }
}