import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import md5 from 'md5'
import axios from 'axios';
import { Utils } from './generic/Utils';
import { CadastroDrawer } from './CadastroDrawer';
import { Link } from '@material-ui/core';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center"> 
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn(props) {

  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [openCadastro, setOpenCadastro] = React.useState(false)

  return (

    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar src='./logo.png' style={{width: '100px', height: '100px'}}/>
        <Typography component="h1" variant="h5">
          Diário de Bordo
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />

          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={ () => {
                setLoading(true)
                let email = document.getElementById('email').value.trim().toLowerCase()
                let pass = md5(document.getElementById('password').value.trim())

                let user = {
                  email: email,
                  password: pass
                }

                axios.post(Utils.getUrl()+'user/login',{...user}).then(

                    resp => {

                      if ( resp.status === 200 ) {                
                        
                        let data = resp.data                         
                        sessionStorage.setItem('userName', data.nome)
                        sessionStorage.setItem('email', data.email)
                        sessionStorage.setItem('userId', data._id)
                        setLoading(false)
                        props.onSucess()

                      }
                      else {
                        setLoading(false) 
                        props.onError()                      
                      }                        
                    }

                ).catch( e => {   
                  
                    console.log(e)
                    setLoading(false) 
                    props.onError()   
              })
              }
            }
          >
            Entrar
          </Button>          
        </form>
          
          <Typography variant="body2" color="textSecondary" align="center"> 
            Ainda não possui conta? &nbsp;
            <Link
              style={{cursor: 'pointer'}}
              variant="body2"
              onClick={() => setOpenCadastro(true)}>
              Cadastre-se!
            </Link>
          </Typography>
          <Link
              style={{cursor: 'pointer'}}
              variant="body2"
              onClick={() => setOpenCadastro(true)}>
              Esqueceu sua senha?
          </Link>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>

      <CadastroDrawer
        open={openCadastro}
        onClose={() => setOpenCadastro(false)}
        onSucess={() => setOpenCadastro(false)}
        onError={() => setOpenCadastro(false)} />
      
      <Backdrop
            style={{ color: '#fff', zIndex: 10000 }}
            open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
    </Container>
  );
}