import React, { Component } from 'react';
import { Grid, MobileStepper, Button, Typography, Slide, Dialog } from '@material-ui/core';
import {Toolbar,IconButton } from '@material-ui/core';
import { TextField, AppBar, Fab, Avatar, Snackbar, Backdrop, CircularProgress } from '@material-ui/core';
import {KeyboardArrowRight, KeyboardArrowLeft } from '@material-ui/icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MuiAlert from '@material-ui/lab/Alert';
import LuxonUtils from '@date-io/luxon';

import {Utils} from '../generic/Utils';
import axios from 'axios';
import '../css/Generic.css'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export class SeresDialog extends Component {

    constructor(props) {
        super(props)

        this.state = {
            cadastro: {
                avatar: '../images/icons/default.png',
                nome: '',
                nascimento: new Date(),
                sexo: '',
                observacoes: ''
            },
            showNomeMessage: false,
            saving: false,
            activeStep: 0
        }

        this.handleSave = this.handleSave.bind(this)
        this.handleSex = this.handleSex.bind(this)
        this.handleNome = this.handleNome.bind(this)
        this.handleAvatar = this.handleAvatar.bind(this)
        this.handleNext = this.handleNext.bind(this)
        this.handleBack = this.handleBack.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    handleClose() {
        this.setState({
            cadastro: {
                avatar: '../images/icons/default.png',
                nome: '',
                nascimento: new Date(),
                sexo: '',
                observacoes: ''
            },
            showNomeMessage: false,
            saving: false,
            activeStep: 0
        })

        this.props.onClose()
    }

    handleSave() {
        let cadastro = {...this.state.cadastro}
        this.setState({saving: true, 
            showNomeMessage: false
        })

        cadastro.userId = sessionStorage.getItem('userId')

        if (!this.props.isPet)
            cadastro.tipo = 'pessoa'
        else
            cadastro.tipo = 'animal'

        axios.post(Utils.getUrl()+"seres/",{...cadastro}).then(
            resp => {
                if ( resp.status === 201 || resp.status === 200) {   

                    sessionStorage.setItem("pessoaNome",cadastro.nome)
                    sessionStorage.setItem("pessoaAvatar",cadastro.avatar)

                    this.setState( {
                        cadastro: {
                            avatar: '../images/icons/default.png',
                            nome: '',
                            nascimento: new Date(),
                            sexo: '',
                            observacoes: ''
                        },
                        showNomeMessage: false,
                        saving: false,
                        activeStep: 0
                    })  

                    this.props.onSave()
                }
                else {
                    this.setState({saving: false})
                }
            }
        ).catch( e => {      
            this.setState({saving: false})
        })
    }

    handleAvatar(item) {
        this.setState({cadastro: {...this.state.cadastro, avatar: item}})
        this.handleNext()
    }

    handleSex(s) {
        this.setState({cadastro: {...this.state.cadastro, sexo: s}})
        this.handleNext()
    }

    handleNome() {
        let step = this.state.activeStep

        if ( !this.state.cadastro.nome ) this.setState({showNomeMessage: true})
        else {
            this.setState({activeStep: step+1})
        }
    }

    handleNext() {
        let step = this.state.activeStep
        this.setState({activeStep: step+1})
    }

    handleBack() {
        let step = this.state.activeStep
        if ( step-1 === -1 ) this.handleClose()
        else this.setState({activeStep: step-1})
    }

    getAvatarList() {
        if ( this.props.isPet )
            return Utils.getPetAvatares()
        else
            return Utils.getAvatares()
    }

    getStepContent() {    

        switch (this.state.activeStep) {

            case 0:
                return (  
                    <Grid container spacing={3}>  
                        <Grid item xs={12}>
                            <Typography variant='h6'>
                                Escolha uma figurinha...
                            </Typography>
                        </Grid>                         
                        <Grid item xs={12}>
                            <Grid container spacing={3} alignItems="center"> 
                                {this.getAvatarList().map((item) => (
                                    <Grid item xs={4} sm={2} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                        <Avatar src={item} 
                                            onClick={() => this.handleAvatar(item)} 
                                            style={{width: '100px', height: '100px', textAlign:'center', cursor: 'pointer'}}/>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>                                                     
                    </Grid> 
                );
            case 1:
                return (
                    <Grid container spacing={3}>  
                        <Grid item xs={12}>
                            <Typography variant='h6'>
                                Conte um pouco sobre ele(a)...
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField id="nome" 
                                required
                                fullWidth
                                error={this.state.showNomeMessage}
                                label="Qual o nome completo?"  
                                variant='outlined' 
                                value={this.state.cadastro.nome}
                                onChange={ (e) => 
                                    this.setState( { cadastro: {...this.state.cadastro, 
                                    nome: e.target.value} } ) }/>
                        </Grid> 

                        <Grid item xs={12}>                        
                            <MuiPickersUtilsProvider utils={LuxonUtils}>
                                <KeyboardDatePicker
                                    id="data"
                                    required
                                    fullWidth
                                    format="dd/MM/yyyy"
                                    label="Data de Nascimento" 
                                    inputVariant='outlined'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}     
                                    value={this.state.cadastro.nascimento}
                                    onChange={ (e) => 
                                        this.setState( { cadastro: {...this.state.cadastro, 
                                        nascimento: new Date(e)} } ) }
                                />   
                            </MuiPickersUtilsProvider>                        
                        </Grid>

                        <Grid item xs={12}>  
                            <div style={{textAlign:'center'}}>                        
                                <Fab variant="extended" color="primary" style={{width: '90%'}} onClick={this.handleNome}>
                                    Próximo
                                </Fab> 
                            </div>   
                        </Grid>   
                    </Grid>
                );
            case 2:
                return (
                    <Grid container spacing={3}>  
                        <Grid item xs={12}>
                            <Typography variant='h6'>
                                {this.state.cadastro.nome} é do sexo...
                            </Typography>
                        </Grid>
                        <Grid item xs={6} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>                            
                            <Avatar src="../images/icons/female.png" 
                                onClick={() => this.handleSex("f")} 
                                style={{width: '100px', height: '100px', cursor: 'pointer', marginBottom: '10px'}}/>
                            <Typography variant='body2'>
                                Feminino
                            </Typography>
                        </Grid>
                        <Grid item xs={6} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>  
                            <Avatar src="../images/icons/male.png" 
                                onClick={() => this.handleSex("m")} 
                                style={{width: '100px', height: '100px', cursor: 'pointer', marginBottom: '10px'}}/>
                            Masculino
                        </Grid>       
                    </Grid>
                );
            
            case 3:
                return (
                    <Grid container spacing={3}>  
                        <Grid item xs={12}>
                            <Typography variant='h6'>
                                Alguma observação adicional?
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField id="observacoes" 
                                fullWidth
                                multiline
                                rows={4}
                                label="Observações?"  
                                variant='outlined' 
                                value={this.state.cadastro.observacoes}
                                onChange={ (e) => 
                                    this.setState( { cadastro: {...this.state.cadastro, 
                                    observacoes: e.target.value} } ) }/>
                        </Grid>
                        <Grid item xs={12}>  
                            <div style={{textAlign:'center'}}>                        
                                <Fab variant="extended" color="primary" style={{width: '90%'}} onClick={this.handleSave}>
                                    Finalizar
                                </Fab> 
                            </div>   
                        </Grid> 
                    </Grid>
                );
            
            default: return;
        
        }
    }

    render() {

        return (
            <Dialog fullScreen 
                open={this.props.open} 
                onClose={this.handleClose} 
                onRendered={this.onRendered}
                TransitionComponent={Transition}>
                    <AppBar position="static" style={{minHeight: '50px'}}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={this.handleClose}>
                                <KeyboardArrowLeft />
                            </IconButton>

                            <Typography component="h1" variant="h6" color="inherit" noWrap>
                                {this.props.isPet?"Novo Pet":"Nova Pessoa"}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <Grid container spacing={3} alignItems="center">
                        
                        <Grid item xs={12}>
                            <MobileStepper
                                variant="dots"
                                steps={4}
                                position="static"
                                activeStep={this.state.activeStep}
                                nextButton={
                                    <Button size="small" onClick={this.handleNext} disabled={[1,3].includes(this.state.activeStep)}>
                                        Pular <KeyboardArrowRight />
                                    </Button>
                                }
                                backButton={
                                    <Button size="small" onClick={this.handleBack} disabled={this.state.activeStep===0}>
                                        <KeyboardArrowLeft />
                                    </Button>
                                }
                            />
                        </Grid>

                        <Grid item xs={12} style={{margin: '15px'}}>
                            {this.getStepContent()}
                        </Grid>

                    </Grid>

                    <Snackbar open={this.state.showNomeMessage} autoHideDuration={6000} 
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => this.setState({showNomeMessage:false})}>
                        <MuiAlert severity="error" elevation={6} variant="filled" onClose={ () => this.setState({showNomeMessage:false})}>
                            Por favor, verifique os campos e tente novamente.
                        </MuiAlert>
                    </Snackbar>

                    <Backdrop
                        style={{ color: '#fff', zIndex: 10000 }}
                        open={this.state.saving}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
            </Dialog>
        )
    }

}