import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Title from '../generic/Title';
import { Paper, Box } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios';
import md5 from 'md5'
import { Utils } from '../generic/Utils';

import '../css/Generic.css'

export class Perfil extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: '',
            nome: '',
            password: '',
            passwordAtual: '',
            erroSenhaNova: false,
            erroSenhaAtual: false,
            showErroPassMessage: false,
            showSuccessMessage:false,
            showErrorMessage:false,
            showEmptyPassMessage: false
        }
        this.salvar = this.salvar.bind(this)
    }

    componentDidMount() {
        let userId = sessionStorage.getItem('userId')
        axios.get(Utils.getUrlUser()+userId).then(
            resp => {
                let data = resp.data
                this.setState({
                    email: data.nome,
                    nome: data.nomeCompleto
                })
            }
        )
    }

    salvar() {
        
        this.setState({erroSenhaNova: false})
        this.setState({showErroPassMessage: false})


        if ( this.state.password.trim() === '' ) {
            this.setState({showEmptyPassMessage:true})
            this.setState({erroSenhaNova: true})
            return
        }

        let userId = sessionStorage.getItem('userId')
        axios.get(Utils.getUrlUser()+userId).then(
            resp => {
                let data = resp.data

                if ( md5(this.state.passwordAtual) === data.senha ) {
                    data.senha = md5(this.state.password)
                    data.nomeCompleto = this.state.nome

                    axios.put(Utils.getUrlUser()+userId, {...data}).then(
                        resp => {
                            if ( resp.status === 201 || resp.status === 200) {  
                                this.setState({showSuccessMessage:true})
                            }
                            else {                                
                                this.setState({showErrorMessage:true})
                            }
                        }
                    )
                }
                else {
                    //Msg de erro de senha
                    this.setState({showErroPassMessage:true})
                    this.setState({erroSenhaAtual: true})
                }
            }
        )
    }

    render() {

        return(
            <Paper className="class-paper">
                <Title>Perfil</Title>
                <TextField 
                    required
                    fullWidth
                    margin="normal"
                    disabled={true}
                    id="email" 
                    label="Login" 
                    value={this.state.email} />

                <TextField 
                    required
                    fullWidth
                    margin="normal"
                    id="nome" 
                    label="Nome" 
                    value={this.state.nome} 
                    onChange={(e) => this.setState( {nome: e.target.value} ) }/>

                <TextField
                    required
                    fullWidth
                    error={this.state.erroSenhaAtual}
                    margin="normal"
                    label="Senha Atual"
                    type="password"
                    id="passwordAtual"
                    onChange={(e) => this.setState( {passwordAtual: e.target.value} ) } />

                <TextField
                    required
                    fullWidth
                    error={this.state.erroSenhaNova}
                    margin="normal"
                    label="Nova Senha"
                    type="password"
                    id="password"
                    onChange={(e) => this.setState( {password: e.target.value} ) } />

                <Box pt={4}/>

                <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={this.salvar}>
                        Salvar
                </Button>

                <Snackbar open={this.state.showErroPassMessage} autoHideDuration={6000} 
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => {this.setState({showErroPassMessage:false}) }}>
                    <MuiAlert severity="error" elevation={6} variant="filled" onClose={() => {this.setState({showErroPassMessage:false}) }}>
                        Senha atual não confere!
                    </MuiAlert>
                </Snackbar>

                <Snackbar open={this.state.showEmptyPassMessage} autoHideDuration={6000} 
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={ () => {this.setState({showEmptyPassMessage:false}) }}>
                    <MuiAlert severity="error" elevation={6} variant="filled" onClose={() => {this.setState({showEmptyPassMessage:false}) }}>
                        Preencha com alguma senha!
                    </MuiAlert>
                </Snackbar>

                <Snackbar open={this.state.showSuccessMessage} autoHideDuration={6000} 
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={ () => {this.setState({showSuccessMessage:false}) }}>
                    <MuiAlert severity="success" elevation={6} variant="filled" onClose={() => {this.setState({showSuccessMessage:false}) }}>
                        Perfil atualizado com sucesso!
                    </MuiAlert>
                </Snackbar>

                <Snackbar open={this.state.showErrorMessage} autoHideDuration={6000} 
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={ () => {this.setState({showErrorMessage:false}) }}>
                    <MuiAlert severity="error" elevation={6} variant="filled" onClose={() => {this.setState({showErrorMessage:false}) }}>
                        Erro ao salvar perfil!
                    </MuiAlert>
                </Snackbar>

            </Paper>
        )
    }
}